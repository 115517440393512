<script setup lang="ts">
import VDraggable from "vuedraggable";
import type { VideoJsPlayer as IPixellotPlayer } from "@pixellot/web-sdk";
import type { IPlayerState } from "~~/modules/pixellot-sdk";
import type { IPlaylist, IPlaylistItem, IEvent, IBasicVideo, IRequestPayloadTag } from "@/types";
import { PxlVideoClipper, UModalConfirm } from "#components";
import { PLAYLIST_TYPE } from "@/videos/constants/event";
import { formatDurationString } from "@/videos/helpers/format-time";

const props = defineProps<{
  playlist: IPlaylist;
  playlistItems: IPlaylistItem[];
  activeVideo: null | IBasicVideo | IEvent;
  playerState: null | IPlayerState;
  activeItem: null | IPlaylistItem;
  player: null | IPixellotPlayer;
}>();
const { t } = useI18n();
const toast = useToast();
const modal = useModal();
const segment = useSegment();
const emit = defineEmits([
  "playlist-item-click",
  "close",
  "toggle-edit-mode",
  "delete-playlist-items",
  "update-playlist-item",
]);

const videoClipperRef = ref<InstanceType<typeof PxlVideoClipper> | null>(null);
const listData = ref<IPlaylistItem[]>([]);
const isCreatePlaylistPopupShown = ref(false);
const isSaveToPlaylistPopupShown = ref(false);
const isClipCreatePopupShown = ref(false);
const isEditPlaylistItem = ref(false);
const hasExistingPlaylists = ref(false);
const selectedItems = ref<IPlaylistItem[]>([]);
const data = ref<Partial<IRequestPayloadTag>>({
  startTime: props.activeItem?.startTime || 0,
  endTime: props.activeItem?.endTime || 0,
});
const { isAllItemsSelected, selectAllItems, selectedItemsMap, toggleItemSelect } = useItemsSelect(listData, "id");

const selectedDuration = computed(() => {
  if (!videoClipperRef.value || !videoClipperRef.value.selectedRange || !props.player) return 0;

  const [rangeStart, rangeEnd] = videoClipperRef.value.selectedRange;
  const duration = rangeEnd - rangeStart;

  return formatDurationString(duration, t);
});

const selectedRangeStart = computed(() => {
  if (!videoClipperRef.value || !videoClipperRef.value.selectedRange || !props.player) return 0;
  return props.player.utils.formatTime(videoClipperRef.value.selectedRange[0]);
});
const selectedRangeEnd = computed(() => {
  if (!videoClipperRef.value || !videoClipperRef.value.selectedRange || !props.player) return 0;
  return props.player.utils.formatTime(videoClipperRef.value.selectedRange[1]);
});
const isPrivatePlaylist = props.playlist?.type === PLAYLIST_TYPE.PRIVATE;
const canBulkDelete = computed(() => isPrivatePlaylist && selectedItemsMap.size > 0 && props.playlist.permissions.canDelete);

watch(() => props.playlistItems, () => {
  // figure out which items are selected and in the new list, then deselect all and reselect the ones that are still in the list
  const toKeepSelected: any[] = [];
  selectedItemsMap.forEach((_, key) => {
    const item = props.playlistItems.find(item => item.id === key);
    if (item) {
      toKeepSelected.push(item);
    }
  });
  selectAllItems(false);
  toKeepSelected.forEach(item => toggleItemSelect(true, item));

  listData.value = props.playlistItems.map(item => ({ ...item }));
}, { immediate: true });

const onError = (err: unknown) => toast.error(parseErrorMessage(err, { t }));

const { isLoading: isPlaylistItemPositionUpdating, execute: updateItemPosition } = useAsyncState(
  (id: string, position: number) => updatePlaylistItemOrder(id, position),
  null,
  {
    immediate: false,
    onSuccess: () => {
      toast.success(t("pages.playlists.modal.order_update_success"));
    },
    onError,
  },
);
const { isLoading: isPlaylistItemUpdating, execute: updatePlaylistItem } = useAsyncState(
  () => editPlaylistItem(selectedItems.value[0].id, data.value),
  null,
  {
    immediate: false,
    onSuccess: (updatedItem: null | IPlaylistItem) => {
      listData.value = listData.value.map(item => (item.id === updatedItem?.id ? { ...updatedItem } : item));
      emit("update-playlist-item", updatedItem);
      toast.success(t("pages.editor.edit_tag_toast_success"));
      closeVideoClipper();

      if (updatedItem) {
        segment.track("Tag Edited", formatTrackPlaylistItem(updatedItem));
      }
    },
    onError,
  },
);

async function onCreatePlaylist(existingPlaylists: boolean) {
  hasExistingPlaylists.value = existingPlaylists;

  if (isSaveToPlaylistPopupShown.value) {
    isSaveToPlaylistPopupShown.value = false;
  }

  // wait for the modal to close, before opening the other
  await nextTick();

  isCreatePlaylistPopupShown.value = true;
}

function onBulkAddToPlaylist() {
  const items = Array.from(selectedItemsMap.values());

  if (!items.length) return toast.error(t("errors.something_went_wrong"));

  selectedItems.value = items;
  isSaveToPlaylistPopupShown.value = true;
}

function onAddToPlaylist(item: IPlaylistItem) {
  selectedItems.value = [item];
  isSaveToPlaylistPopupShown.value = true;
}

function onEditPlaylistItem(item: IPlaylistItem) {
  if (item.playlist_source.id !== props.activeVideo?.id) {
    emit("playlist-item-click", item);
    props.player?.one("loadedmetadata", () => handleEditMode(item));
    return;
  }

  handleEditMode(item);
}

function removeItemsFromList() {
  const idsSet = new Set(selectedItems.value.map(item => item.id));

  listData.value = listData.value.filter(item => !idsSet.has(item.id));
}

function onListOrderUpdate($event: { newIndex: number; oldIndex: number }) {
  const { newIndex, oldIndex } = $event;
  const itemId = listData.value[newIndex].id;

  updateItemPosition(0, itemId, newIndex);

  segment.track("Tag Order Changed", {
    ...formatTrackPlaylistItem(listData.value[newIndex]),
    ...{ new_position: newIndex, old_position: oldIndex },
  });
}

function onCreateClip() {
  isClipCreatePopupShown.value = true;
}

function closeVideoClipper() {
  selectedItems.value = [];
  isEditPlaylistItem.value = false;
  props.player?.trimAPI.hide();
  emit("toggle-edit-mode");

  props.player?.off("loadedmetadata", onEditModeLoadedMetaData);
}

function setClipperRange(item: IPlaylistItem) {
  if (item && props.player) {
    props.player.trimAPI.setRange(item.startTime - 40 > 0 ? item.startTime - 40 : 0, item.endTime);
    videoClipperRef.value?.setRange(item.startTime, item.endTime);
  }
}

function handleEditMode(item: IPlaylistItem) {
  selectedItems.value = [item];
  props.player?.pause();
  emit("toggle-edit-mode", item);
  isEditPlaylistItem.value = true;

  // wait until the player is ready to set the clipper range
  props.player?.one("loadedmetadata", onEditModeLoadedMetaData);
}

function onEditModeLoadedMetaData() {
  nextTick(() => {
    if (selectedItems.value?.length > 0) {
      setClipperRange(selectedItems.value[0]);
    }
  });
}

async function onPlaylistsModalCreateClose(playlistModified: boolean) {
  isCreatePlaylistPopupShown.value = false;

  if (hasExistingPlaylists.value || playlistModified) {
    // wait for the modal to close, before opening the other
    await nextTick();

    isSaveToPlaylistPopupShown.value = true;
  }
}

function onListItemSelect(state: boolean, item: IPlaylistItem) {
  toggleItemSelect(state, item);

  segment.track(state ? "Tag Selected" : "Tag Deselected", formatTrackTag(item, props.activeVideo as IEvent));
}

function onListItemSelectAll(state: boolean) {
  const beforeSelectedItemsCount = selectedItemsMap.size;

  selectAllItems(state);

  if (state) {
    segment.track("All Tags Selected", {
      ...formatTrackEvent(props.activeVideo as IEvent),
      ...{ number_of_tags: selectedItemsMap.size },
    });
  }
  else {
    segment.track("All Tags Deselected", {
      ...formatTrackEvent(props.activeVideo as IEvent),
      ...{ number_of_tags: beforeSelectedItemsCount },
    });
  }
}

function onDeletePlaylistItemClick(item: IPlaylistItem) {
  modal.open(UModalConfirm, {
    heading: t("pages.editor.tag_delete_confirm"),
    icon: "tag",
    variant: "delete",
    confirmLabel: t("labels.delete"),
    cancelLabel: t("labels.cancel"),
    description: t("labels.action_cannot_be_reversed"),
    confirmFn: () => {
      return deletePlaylistItem(item.id)
        .then(() => {
          segment.track("Tag Deleted", formatTrackPlaylistItem(item));
          emit("delete-playlist-items", [item]);
          toast.success(t("pages.editor.delete_tag_toast_success"));
          removeItemsFromList();
        })
        .catch(onError);
    },
  });
}

function onDeletePlaylistItemsClick(items: IPlaylistItem[]) {
  modal.open(UModalConfirm, {
    heading: t("pages.editor.tag_bulk_delete_confirm"),
    icon: "tag",
    variant: "delete",
    confirmLabel: t("labels.delete"),
    cancelLabel: t("labels.cancel"),
    description: t("labels.action_cannot_be_reversed"),
    confirmFn: () => {
      return deletePlaylistItems(items.map(i => i.id))
        .then(() => {
          items.forEach(item => segment.track("Tag Deleted", formatTrackPlaylistItem(item)));
          emit("delete-playlist-items", items);
          toast.success(t("pages.editor.bulk_delete_tag_toast_success"));
          removeItemsFromList();
        })
        .catch(onError);
    },
  });
}

watch(
  () => videoClipperRef.value?.selectedRange,
  (range) => {
    if (!range) return;

    // Updating time ranges from the selected in player controls.
    data.value.startTime = Math.floor(range[0]);
    data.value.endTime = Math.floor(range[1]);
  },
);
</script>

<template>
  <div class="h-96 w-full bg-white dark:bg-neutral-dark-700 lg:h-[calc(100%-83px)]">
    <div class="h-full">
      <div v-if="isEditPlaylistItem">
        <Teleport to="#floating-player-footer">
          <div class="flex h-full flex-col gap-4 border-r border-neutral-light-200 pt-4 dark:border-neutral-dark-900">
            <div class="flex items-center justify-center gap-4">
              <UInput
                class="w-[120px]"
                input-class="text-center py-[13px]"
                :model-value="selectedRangeStart"
                readonly
              />
              <span class="dark:text-white">-</span>
              <UInput
                class="w-[120px]"
                input-class="text-center py-[13px]"
                :model-value="selectedRangeEnd"
                readonly
              />
            </div>

            <PxlVideoClipper
              v-if="player && playerState"
              ref="videoClipperRef"
              :player="player"
              :player-state="playerState"
            />

            <div class="text-center text-sm font-medium text-neutral-light-800">
              {{ selectedDuration }}
            </div>
            <div class="mt-auto">
              <div>
                <UDivider lighter />
              </div>
              <div class="mx-auto my-4 flex w-full max-w-lg items-center justify-center gap-4 px-6">
                <UButton
                  class="w-48"
                  size="lg"
                  variant="secondary"
                  :label="t('labels.cancel')"
                  @click="closeVideoClipper"
                />
                <UButton
                  class="w-48"
                  size="lg"
                  :label="t('labels.save')"
                  :loading="isPlaylistItemUpdating"
                  @click="updatePlaylistItem"
                />
              </div>
            </div>
          </div>
        </Teleport>
        <div class="mx-auto mt-10 w-[400px] text-center text-neutral-light-800 dark:text-neutral-dark-200">
          <UIcon
            name="tag"
            class="mx-auto mb-4 block text-[32px] text-current"
          />
          <p class="text-center text-sm font-medium">
            You are now in tag edit mode. To edit please use the panel below the player.
          </p>
        </div>
      </div>
      <div
        v-else
        class="relative h-full overflow-hidden"
      >
        <div class="relative mt-1.5 flex h-[39px] items-center justify-between">
          <div class="flex gap-4 px-4">
            <UCheckbox
              class="ltr:ml-6 rtl:mr-6"
              :label="selectedItemsMap.size > 0 ? t('labels.n_items_selected', { n: selectedItemsMap.size }) : t('labels.select_all') + ' (' + props.playlistItems.length + ')'"
              name="select-all-playlist-items"
              :model-value="isAllItemsSelected"
              :disabled="!props.playlistItems.length"
              :indeterminate="isAllItemsSelected ? false : selectedItemsMap.size > 0"
              @update:model-value="onListItemSelectAll($event)"
            />
          </div>
        </div>

        <RLoadingOverlay
          v-if="isPlaylistItemPositionUpdating"
          class="bg-white !bg-opacity-100 dark:bg-neutral-dark-700"
        />

        <VDraggable
          v-model="listData"
          handle=".handle"
          item-key="id"
          class="gap-1 overflow-auto px-4"
          :class="selectedItemsMap.size >= 1 ? 'h-[calc(100%-46px-74px)]' : 'h-[calc(100%-46px)]'"
          :disabled="isPlaylistItemPositionUpdating"
          @update="onListOrderUpdate"
        >
          <template #item="{ element: playlistItem, index }">
            <PlaylistsListItem
              :key="index"
              :playlist="playlist"
              :playlist-item="playlistItem"
              :is-selected="selectedItemsMap.has(playlistItem.id)"
              :active-video="activeVideo"
              :active-item="activeItem"
              :is-video-paused="playerState?.paused"
              @playlist-item-click="emit('playlist-item-click', $event)"
              @select="onListItemSelect($event, playlistItem)"
              @close-player="emit('close')"
              @add-to-playlist="onAddToPlaylist($event)"
              @edit="onEditPlaylistItem($event)"
              @delete="onDeletePlaylistItemClick($event)"
            />
          </template>
        </VDraggable>

        <UDivider
          v-if="selectedItemsMap.size >= 1"
          lighter
        />

        <div
          v-if="selectedItemsMap.size >= 1"
          class="mx-auto my-4 grid max-w-sm grid-cols-2 gap-4 px-4 max-md:grid-cols-1"
        >
          <UMenu :dropdown="{ placement: 'top-start', distance: 14 }">
            <template #trigger="{ toggle }">
              <UButton
                block
                size="lg"
                class="font-medium"
                variant="primary-outline"
                :icon="{ name: 'chevron-down', class: 'w-3 h-3' }"
                :label="t('labels.more_actions')"
                @click="toggle()"
              />
            </template>

            <UList>
              <UListItem
                icon="download"
                :text="t('labels.download_as_highlights')"
                disabled
              />
              <UListItem
                icon="add"
                :text="t('labels.save_to_playlist')"
                @click="() => onBulkAddToPlaylist()"
              />
              <UListItem
                v-if="canBulkDelete"
                :icon="{ name: 'delete', class: 'text-red-500 dark:text-red-500' }"
                class="!text-red-500"
                :text="t('labels.delete')"
                @click="() => onDeletePlaylistItemsClick(Array.from(selectedItemsMap.values()))"
              />
            </UList>
          </UMenu>

          <UButton
            size="lg"
            type="submit"
            icon="clip"
            :label="t('pages.editor.add_highlight')"
            @click="onCreateClip"
          />
        </div>
      </div>
    </div>
  </div>

  <PlaylistsModalCreate
    v-if="isCreatePlaylistPopupShown"
    :show-instructions="!hasExistingPlaylists"
    @close="onPlaylistsModalCreateClose"
  />

  <PlaylistsModalSaveToPlaylist
    v-if="isSaveToPlaylistPopupShown"
    :tags="selectedItems"
    :playlist-id="props.playlist.id"
    source="playlist"
    @create-playlist="onCreatePlaylist"
    @add-to-playlist-success="selectedItemsMap.clear()"
    @close="
      isSaveToPlaylistPopupShown = false;
      selectedItems = [];
    "
  />

  <EventModalCreateClip
    v-if="isClipCreatePopupShown"
    is-clip-from-playlist
    :playlist="props.playlist"
    :tags="Array.from(selectedItemsMap.values())"
    @close="isClipCreatePopupShown = false"
  />
</template>
