<script setup lang="ts">
const emit = defineEmits(["back"]);

const { t } = useI18n();
const colorMode = useColorMode();
const onBackClick = () => emit("back");

const themeOptions = computed(() => [
  { value: "system", text: t("labels.theme_use_system") },
  { value: "light", text: t("labels.theme_light") },
  { value: "dark", text: t("labels.theme_dark") },
]);
</script>

<template>
  <UList>
    <UListItem
      :on-click-close-menu="false"
      size="lg"
      :icon="{ name: 'arrow-right', class: 'transform rotate-180 text-black dark:text-white' }"
      @click="onBackClick()"
    >
      <span class="font-bold">{{ t("labels.appearance") }}</span>
    </UListItem>
    <UDivider lighter />
    <p class="px-4 pb-2 pt-5 text-sm font-medium text-neutral-light-800">
      {{ t("labels.browser_only_setting") }}
    </p>
    <UListItem
      v-for="option in themeOptions"
      :key="option.value"
      size="md"
      :text="option.text"
      :icon="{ name: colorMode.preference === option.value ? 'check' : 'empty', class: 'text-blue-500' }"
      :on-click-close-menu="false"
      @click="colorMode.preference = option.value"
    />
  </UList>
</template>
