<script setup lang="ts">
import type { IEvent } from "@/types";
import { MenuItemEventRequestVideoExport } from "#components";

const props = defineProps<{
  event: IEvent;
}>();
</script>

<template>
  <MenuItemEventRequestVideoExport
    v-for="[type] in props.event.urls"
    :key="type"
    :type="type"
    :event="props.event"
  />
</template>
