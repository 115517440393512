<script lang="ts" setup>
import type { NuxtError } from "#app";
import DefaultWrapper from "~/common/layouts/default-wrapper.vue";

const props = defineProps<{ error: NuxtError }>();
const user = useUser();
</script>

<template>
  <DefaultWrapper>
    <AppLayout
      has-divider
      :hide-logo="Boolean(user)"
    >
      <RError v-if="props.error" />
    </AppLayout>
  </DefaultWrapper>
</template>
