<script setup lang="ts">
import type { IAthleteHighlight, IBasicVideo, IClip, IEvent, IGameHighlight, IPlaylist } from "@/types";
import { EventModalShare } from "#components";
import { EVENT_STATUS, PLAYLIST_TYPE, VIDEO_TYPE } from "@/constants";

const props = defineProps<{
  video?: IEvent | IBasicVideo | null;
  playlist?: null | IPlaylist;
  getSharedTime?: () => number;
}>();

const { t } = useI18n();
const modal = useModal();
const shareTime = ref(0);
const isEvent = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IEvent => vid?.type === VIDEO_TYPE.EVENT;
const isClip = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IClip => vid?.type === VIDEO_TYPE.CLIP;
const isGameHighlight = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IGameHighlight => vid?.type === VIDEO_TYPE.GAME_HIGHLIGHT || vid?.type === "autohighlight";
const isAthleteHighlight = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IAthleteHighlight => vid?.type === VIDEO_TYPE.ATHLETE_HIGHLIGHT;

const isAvailable = computed(() => {
  if (isEvent(props.video)) {
    const isLive = props.video.status === EVENT_STATUS.LIVE;
    const isUpcoming = props.video.status === EVENT_STATUS.UPCOMING;

    return !isLive && !isUpcoming;
  }
  else if (props.playlist || isClip(props.video)) {
    return true;
  }

  return isGameHighlight(props.video) || isAthleteHighlight(props.video);
});
const isDisabled = computed(() => {
  if (isClip(props.video)) {
    return props.video.isPending;
  }
  else if (props.playlist) {
    return props.playlist.type === PLAYLIST_TYPE.SYSTEM;
  }

  return false;
});

function onClick() {
  if (props.getSharedTime) {
    shareTime.value = props.getSharedTime();
  }

  modal.open(EventModalShare, { video: props.video, playlist: props.playlist, shareTime: shareTime.value });
}
</script>

<template>
  <UListItem
    v-if="isAvailable"
    icon="share"
    :text="t('labels.share')"
    :disabled="isDisabled"
    :on-click="onClick"
  />
</template>
