<script setup lang="ts">
import type { RouteLocationRaw } from "#vue-router";
import { UAvatar, UMenu } from "#components";
import type { TSportType } from "@/types";

export interface ITeamCardItem {
  key?: string;
  name: string;
  value?: string;
  valueClass?: string;
  hidden?: boolean;
  avatar?: InstanceType<typeof UAvatar>["$props"];
  colors?: (string | undefined)[];
  link?: RouteLocationRaw;
  sportType?: TSportType;
}

const props = withDefaults(
  defineProps<{
    logoUrl?: string;
    logoPadded?: boolean;
    title: string;
    subTitle?: string;
    menu?: InstanceType<typeof UMenu>["$props"];
    items?: ITeamCardItem[];
    isAthleteInvited?: boolean;
  }>(),
  {
    logoPadded: true,
  },
);

const { t } = useI18n();
const slots = useSlots();
const items = computed(() => (props.items || []).filter(i => !i.hidden));
</script>

<template>
  <div class="flex flex-col rounded bg-white dark:bg-neutral-dark-700">
    <div class="relative flex items-center gap-2 p-3">
      <UAvatar
        :src="props.logoUrl"
        :alt="props.title"
        :size="56"
        :padded="props.logoPadded"
      />
      <div class="flex h-14 max-w-[calc(100%-90px)] flex-col justify-center gap-1">
        <p
          class="line-clamp-2 break-words text-sm font-bold text-black dark:text-white"
          :title="props.title"
        >
          {{ props.title }}
        </p>
        <p
          v-if="props.subTitle"
          class="truncate text-xs font-medium text-neutral-light-800 dark:text-neutral-dark-200"
        >
          {{ props.subTitle }}
        </p>
      </div>
      <UMenu
        v-if="props.menu && props.menu.items?.length"
        v-bind="props.menu"
        class="absolute top-3 ltr:right-3 rtl:left-3"
      />
    </div>

    <UDivider lighter />

    <div
      v-if="items.length"
      class="px-0 py-1 text-xs font-medium"
    >
      <slot name="table-content">
        <table class="w-full table-fixed border-separate border-spacing-x-3 border-spacing-y-2">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.key || item.name"
            >
              <th class="w-[65px] p-0 text-left font-medium text-neutral-light-800 dark:text-neutral-dark-200">
                {{ item.name }}:
              </th>
              <td class="p-0">
                <slot :name="`item-${item.key || item.name}-value`" />

                <div
                  v-if="item.value || item.sportType"
                  class="flex w-full items-center justify-between gap-2"
                >
                  <span
                    v-if="item.value"
                    :class="['line-clamp-1 break-all', item.valueClass]"
                  >
                    {{ item.value }}
                  </span>
                  <span v-if="item.sportType">
                    {{ t(`sport_types.${item.sportType}`) }}
                  </span>
                  <IconSportType
                    v-if="item.sportType"
                    :sport-type="item.sportType"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </slot>
    </div>
    <div
      v-if="slots.default"
      class="p-3"
    >
      <slot />
    </div>
  </div>
</template>
