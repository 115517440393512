<script lang="ts" setup>
const baseURL = useRuntimeConfig().app.baseURL;

const darkLogo = `${baseURL}images/logo/pixellot-logo-dark.png`;
const lightLogo = `${baseURL}images/logo/pixellot-logo-light.png`;

const { t } = useI18n();
const colorMode = useColorMode();
const logoURL = computed(() => (colorMode.value === "dark" ? lightLogo : darkLogo));
const user = useUser();
const userProfile = useUserProfile();
const isB2CAdmin = computed(() => !!userProfile.value);
const { links, logoPath } = useNavbar();
</script>

<template>
  <div>
    <RDrawer placement="left">
      <template #toggle="{ toggle }">
        <div class="flex items-center gap-6">
          <UButton
            variant="link-secondary"
            icon="list"
            @click="toggle()"
          />
          <NuxtLink :to="logoPath">
            <img
              :src="logoURL"
              width="140"
              height="22"
              aria-label="Pixellot logo"
              alt="Pixellot logo"
            >
          </NuxtLink>
        </div>
      </template>
      <template #content="{ close }">
        <nav class="flex flex-col justify-between px-4 py-8">
          <div>
            <div class="mb-10 flex items-center gap-6">
              <UButton
                variant="link-secondary"
                icon="close"
                @click="close()"
              />
              <NuxtLink :to="logoPath">
                <img
                  :src="logoURL"
                  width="140"
                  height="22"
                  aria-label="Pixellot logo"
                  alt="Pixellot logo"
                >
              </NuxtLink>
            </div>

            <div>
              <template
                v-for="item in links"
                :key="item.label"
              >
                <NuxtLink
                  v-if="item.type === 'link' && !item.hidden"
                  class="nav-link-mobile"
                  :to="item.to"
                  :class="{ active: $route.path.startsWith(item.to || '') }"
                  active-class="active"
                  @click="close()"
                >
                  <UIcon
                    v-if="item.iconName"
                    :name="item.iconName"
                    class="text-xl"
                  />
                  <span class="truncate">{{ item.label }}</span>
                </NuxtLink>
                <UDivider
                  v-else-if="item.type === 'separator' && !item.hidden"
                  class="my-4"
                  lighter
                />
              </template>
            </div>
          </div>

          <template v-if="user">
            <UBanner
              v-if="!user.hasEmailVerified && !user.hasB2BAccount && isB2CAdmin"
              icon="verified"
              :text="t('labels.alert_email_verification')"
              class="flex-col items-center text-center"
            />
          </template>
        </nav>
      </template>
    </RDrawer>
  </div>
</template>

<style>
.nav-link-mobile {
  @apply relative flex items-center gap-3 my-2 px-4 py-2 rounded text-neutral-light-800 dark:text-neutral-dark-200 text-base font-medium dark:hover:text-white hover:text-blue-500;
}

.nav-link-mobile.active {
  @apply text-blue-500;
}

.nav-link-mobile.active::before {
  @apply absolute top-0 -left-[18px] rounded-3xl w-1.5 h-full bg-blue-500;
  content: "";
}
</style>
