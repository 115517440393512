<script lang="ts" setup>
import type { PxlIcon } from "@/common/components/U/Icon";
import { iconBind } from "@/common/components/U/Icon";

const props = defineProps<{
  icon?: PxlIcon;
  text?: string;
}>();
const slots = useSlots();
</script>

<template>
  <div class="flex min-h-12 w-full items-start justify-center gap-3 break-words rounded bg-dark-blue-500 p-4 text-white">
    <div
      v-if="props.icon"
      class="grid size-10 place-items-center rounded-full bg-dark-blue-400"
    >
      <UIcon v-bind="iconBind(props.icon, { class: 'text-base' })" />
    </div>
    <div
      v-if="slots.default?.() || props.text"
      class="shrink grow basis-0 text-xs font-medium"
    >
      <slot>
        {{ props.text }}
      </slot>
    </div>
  </div>
</template>
