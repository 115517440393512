
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as billingTBlS29FkcAMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue?macro=true";
import { default as breakdownsjtWuM9uCRLMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue?macro=true";
import { default as feature_45flagsnknv1fMjeWMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue?macro=true";
import { default as generalPrD1IwjM4NMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue?macro=true";
import { default as indexhghOKxyxC9Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue?macro=true";
import { default as ordersUTvXrjGzTDMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue?macro=true";
import { default as subscriptions1GSd0FVFyEMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue?macro=true";
import { default as bioEk76upKEdvMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue?macro=true";
import { default as chartsqF4MVnbWVcMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue?macro=true";
import { default as eventsTE1W4oHHg2Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue?macro=true";
import { default as indexBWPLiQ25SBMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue?macro=true";
import { default as statsKAYr0qRenDMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue?macro=true";
import { default as _91athleteId_93nAz9i2EDlUMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue?macro=true";
import { default as _91athleteId_93M0K0aB2LrkMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue?macro=true";
import { default as _91type_93oyUgVANBhmMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue?macro=true";
import { default as indexLlLRvXJJdqMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue?macro=true";
import { default as merge7V5gkte4VLMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue?macro=true";
import { default as new06pzoQeWJWMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue?macro=true";
import { default as recovery2B4mzoFeTkMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue?macro=true";
import { default as verification0CMhNuHAlkMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue?macro=true";
import { default as organizationxSm3Yo6x21Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue?macro=true";
import { default as teamxbfa9okK07Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue?macro=true";
import { default as loginVaZcCAuvtcMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue?macro=true";
import { default as logoutMn888Pg9rQMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue?macro=true";
import { default as forgot_45mail_45sent4fpabxYHqcMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue?macro=true";
import { default as forgotziLimcxMDoMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue?macro=true";
import { default as resetRRW4b8qiBNMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue?macro=true";
import { default as redirectjpC1rmUVnWMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue?macro=true";
import { default as indexCO2vqo93TEMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue?macro=true";
import { default as localZKvONDCgOxMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue?macro=true";
import { default as _91_46_46_46slug_93pAjX99MuYNMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue?macro=true";
import { default as athlete_45statsyctWHtR7tqMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue?macro=true";
import { default as _91type_935O9nPc56d6Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue?macro=true";
import { default as indexxfJdaA8gTOMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue?macro=true";
import { default as indexSMHT2SInDtMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue?macro=true";
import { default as play_45by_45playWwC1nWRMINMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue?macro=true";
import { default as team_45statsxkn9o0taY5Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue?macro=true";
import { default as _91eventId_93lBz1DsX54RMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue?macro=true";
import { default as _91eventId_93I5RZc1uxsuMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue?macro=true";
import { default as _91eventId_937z94iddqnxMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue?macro=true";
import { default as indexD7T2Rll8boMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue?macro=true";
import { default as _91type_93y6MOjYxp3hMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue?macro=true";
import { default as highlightsEzuDUwQicXMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue?macro=true";
import { default as indexffl1rtu4rSMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue?macro=true";
import { default as athletesNzYy9OaOolMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue?macro=true";
import { default as eventsMhbMciIclrMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue?macro=true";
import { default as indexo4yzBiGYvLMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue?macro=true";
import { default as leaderboardsbJjGphBjNcMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue?macro=true";
import { default as standingsSNMsOfL3mJMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue?macro=true";
import { default as teamsbjvwy1GTAYMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue?macro=true";
import { default as _91leagueId_93Sx9bB2PLpLMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue?macro=true";
import { default as _91leagueId_93VZyA34o1H9Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue?macro=true";
import { default as _91type_93ehiCoF9EqYMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue?macro=true";
import { default as indextst8QC9T6GMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue?macro=true";
import { default as newlNFsjUzy1tMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue?macro=true";
import { default as adminsOJIsydBXqJMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue?macro=true";
import { default as indexevfkXMBm9AMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue?macro=true";
import { default as teamsezCDC6PODDMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue?macro=true";
import { default as _91organizationId_93QqCaZHh2P9Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue?macro=true";
import { default as _91organizationId_93kDeRGaqR12Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue?macro=true";
import { default as indexxoRwXb05WfMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue?macro=true";
import { default as new9JAs37iSpFMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue?macro=true";
import { default as practicesh14lScUbUoMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue?macro=true";
import { default as athletes8cM1HvMtkMMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue?macro=true";
import { default as breakdowns_45and_45servicesJ0xWaZQbGEMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue?macro=true";
import { default as coaches8VOoZru0K6Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue?macro=true";
import { default as events1hZwYEdllfMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue?macro=true";
import { default as indexUajzHxP43sMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue?macro=true";
import { default as membersZHXtQJ45Q1Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue?macro=true";
import { default as opponentskMqaBvdrVJMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue?macro=true";
import { default as rostersJ6ZnUngbhCMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue?macro=true";
import { default as team_45statsVTEVW7pnY3Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue?macro=true";
import { default as _91teamId_93X7Ofhqtoa0Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue?macro=true";
import { default as edit9kJXm1X9WDMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue?macro=true";
import { default as newRE5V7gvD4VMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue?macro=true";
import { default as _91teamId_930GfLZdoLGIMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue?macro=true";
import { default as _91type_93LUAjZU3uAPMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue?macro=true";
import { default as replay52AmooCJUmMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue?macro=true";
import { default as index8xYLwDGybtMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue?macro=true";
import { default as joinGSl4M0NUkWMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue?macro=true";
import { default as newu5mykrJUFXMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue?macro=true";
import { default as indexpIS20iqyhNMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue?macro=true";
import { default as pixellotdd97YoYzlLMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue?macro=true";
import { default as favorites9ZY8UUZyVSMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue?macro=true";
import { default as index9BE2HBCsJ3Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue?macro=true";
import { default as contentOYFEtsHOAlMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue?macro=true";
import { default as profileCgIVDUIuXrMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue?macro=true";
import { default as _91type_93gXvKZpR5ZoMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue?macro=true";
export default [
  {
    name: "account-billing___en___default",
    path: "/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___en",
    path: "/en/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___es",
    path: "/es/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___de",
    path: "/de/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___fr",
    path: "/fr/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___it",
    path: "/it/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___he",
    path: "/he/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___hu",
    path: "/hu/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___nl",
    path: "/nl/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-billing___uk-UA",
    path: "/uk-UA/account/billing",
    meta: billingTBlS29FkcAMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/billing.vue")
  },
  {
    name: "account-breakdowns___en___default",
    path: "/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___en",
    path: "/en/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___es",
    path: "/es/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___de",
    path: "/de/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___fr",
    path: "/fr/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___it",
    path: "/it/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___he",
    path: "/he/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___hu",
    path: "/hu/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___nl",
    path: "/nl/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-breakdowns___uk-UA",
    path: "/uk-UA/account/breakdowns",
    meta: breakdownsjtWuM9uCRLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/breakdowns.vue")
  },
  {
    name: "account-feature-flags___en___default",
    path: "/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___en",
    path: "/en/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___es",
    path: "/es/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___de",
    path: "/de/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___fr",
    path: "/fr/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___it",
    path: "/it/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___he",
    path: "/he/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___hu",
    path: "/hu/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___nl",
    path: "/nl/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-feature-flags___uk-UA",
    path: "/uk-UA/account/feature-flags",
    meta: feature_45flagsnknv1fMjeWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/feature-flags.vue")
  },
  {
    name: "account-general___en___default",
    path: "/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___en",
    path: "/en/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___es",
    path: "/es/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___de",
    path: "/de/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___fr",
    path: "/fr/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___it",
    path: "/it/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___he",
    path: "/he/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___hu",
    path: "/hu/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___nl",
    path: "/nl/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account-general___uk-UA",
    path: "/uk-UA/account/general",
    meta: generalPrD1IwjM4NMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/general.vue")
  },
  {
    name: "account___en___default",
    path: "/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___es",
    path: "/es/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___de",
    path: "/de/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___fr",
    path: "/fr/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___it",
    path: "/it/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___he",
    path: "/he/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___hu",
    path: "/hu/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___nl",
    path: "/nl/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account___uk-UA",
    path: "/uk-UA/account",
    meta: indexhghOKxyxC9Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/index.vue")
  },
  {
    name: "account-orders___en___default",
    path: "/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___en",
    path: "/en/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___es",
    path: "/es/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___de",
    path: "/de/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___fr",
    path: "/fr/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___it",
    path: "/it/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___he",
    path: "/he/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___hu",
    path: "/hu/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___nl",
    path: "/nl/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-orders___uk-UA",
    path: "/uk-UA/account/orders",
    meta: ordersUTvXrjGzTDMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/orders.vue")
  },
  {
    name: "account-subscriptions___en___default",
    path: "/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___en",
    path: "/en/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___es",
    path: "/es/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___de",
    path: "/de/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___fr",
    path: "/fr/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___it",
    path: "/it/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___he",
    path: "/he/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___hu",
    path: "/hu/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___nl",
    path: "/nl/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: "account-subscriptions___uk-UA",
    path: "/uk-UA/account/subscriptions",
    meta: subscriptions1GSd0FVFyEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/account/pages/account/subscriptions.vue")
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___en___default",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___en___default",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___en___default",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___en___default",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___en___default",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/en/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___en",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___en",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___en",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___en",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___en",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/es/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___es",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___es",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___es",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___es",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___es",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/de/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___de",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___de",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___de",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___de",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___de",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/fr/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___fr",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___fr",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___fr",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___fr",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___fr",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/it/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___it",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___it",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___it",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___it",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___it",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/he/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___he",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___he",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___he",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___he",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___he",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/hu/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___hu",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___hu",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___hu",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___hu",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___hu",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/nl/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___nl",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___nl",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___nl",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___nl",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___nl",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: _91athleteId_93nAz9i2EDlUMeta?.name,
    path: "/uk-UA/athletes/:athleteId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId].vue"),
    children: [
  {
    name: "athletes-athleteId-bio___uk-UA",
    path: "bio",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/bio.vue")
  },
  {
    name: "athletes-athleteId-charts___uk-UA",
    path: "charts",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/charts.vue")
  },
  {
    name: "athletes-athleteId-events___uk-UA",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/events.vue")
  },
  {
    name: "athletes-athleteId___uk-UA",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/index.vue")
  },
  {
    name: "athletes-athleteId-stats___uk-UA",
    path: "stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/[athleteId]/stats.vue")
  }
]
  },
  {
    name: "athletes-edit-athleteId___en___default",
    path: "/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___en",
    path: "/en/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___es",
    path: "/es/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___de",
    path: "/de/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___fr",
    path: "/fr/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___it",
    path: "/it/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___he",
    path: "/he/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___hu",
    path: "/hu/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___nl",
    path: "/nl/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-edit-athleteId___uk-UA",
    path: "/uk-UA/athletes/edit/:athleteId()",
    meta: _91athleteId_93M0K0aB2LrkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/edit/[athleteId].vue")
  },
  {
    name: "athletes-events-type___en___default",
    path: "/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___en",
    path: "/en/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___es",
    path: "/es/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___de",
    path: "/de/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___fr",
    path: "/fr/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___it",
    path: "/it/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___he",
    path: "/he/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___hu",
    path: "/hu/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___nl",
    path: "/nl/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes-events-type___uk-UA",
    path: "/uk-UA/athletes/events/:type()",
    meta: _91type_93oyUgVANBhmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/events/[type].vue")
  },
  {
    name: "athletes___en___default",
    path: "/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___en",
    path: "/en/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___es",
    path: "/es/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___de",
    path: "/de/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___fr",
    path: "/fr/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___it",
    path: "/it/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___he",
    path: "/he/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___hu",
    path: "/hu/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___nl",
    path: "/nl/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes___uk-UA",
    path: "/uk-UA/athletes",
    meta: indexLlLRvXJJdqMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/index.vue")
  },
  {
    name: "athletes-merge___en___default",
    path: "/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___en",
    path: "/en/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___es",
    path: "/es/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___de",
    path: "/de/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___fr",
    path: "/fr/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___it",
    path: "/it/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___he",
    path: "/he/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___hu",
    path: "/hu/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___nl",
    path: "/nl/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-merge___uk-UA",
    path: "/uk-UA/athletes/merge",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/merge.vue")
  },
  {
    name: "athletes-new___en___default",
    path: "/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___en",
    path: "/en/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___es",
    path: "/es/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___de",
    path: "/de/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___fr",
    path: "/fr/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___it",
    path: "/it/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___he",
    path: "/he/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___hu",
    path: "/hu/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___nl",
    path: "/nl/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "athletes-new___uk-UA",
    path: "/uk-UA/athletes/new",
    meta: new06pzoQeWJWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/athletes/pages/athletes/new.vue")
  },
  {
    name: "auth-email-recovery___en___default",
    path: "/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___en",
    path: "/en/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___es",
    path: "/es/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___de",
    path: "/de/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___fr",
    path: "/fr/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___it",
    path: "/it/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___he",
    path: "/he/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___hu",
    path: "/hu/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___nl",
    path: "/nl/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-recovery___uk-UA",
    path: "/uk-UA/auth/email/recovery",
    meta: recovery2B4mzoFeTkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/recovery.vue")
  },
  {
    name: "auth-email-verification___en___default",
    path: "/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___en",
    path: "/en/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___es",
    path: "/es/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___de",
    path: "/de/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___fr",
    path: "/fr/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___it",
    path: "/it/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___he",
    path: "/he/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___hu",
    path: "/hu/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___nl",
    path: "/nl/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-email-verification___uk-UA",
    path: "/uk-UA/auth/email/verification",
    meta: verification0CMhNuHAlkMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/email/verification.vue")
  },
  {
    name: "auth-invite-organization___en___default",
    path: "/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___en",
    path: "/en/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___es",
    path: "/es/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___de",
    path: "/de/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___fr",
    path: "/fr/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___it",
    path: "/it/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___he",
    path: "/he/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___hu",
    path: "/hu/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___nl",
    path: "/nl/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-organization___uk-UA",
    path: "/uk-UA/auth/invite/organization",
    meta: organizationxSm3Yo6x21Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/organization.vue")
  },
  {
    name: "auth-invite-team___en___default",
    path: "/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___en",
    path: "/en/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___es",
    path: "/es/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___de",
    path: "/de/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___fr",
    path: "/fr/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___it",
    path: "/it/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___he",
    path: "/he/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___hu",
    path: "/hu/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___nl",
    path: "/nl/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-invite-team___uk-UA",
    path: "/uk-UA/auth/invite/team",
    meta: teamxbfa9okK07Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/invite/team.vue")
  },
  {
    name: "auth-login___en___default",
    path: "/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___es",
    path: "/es/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___de",
    path: "/de/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___fr",
    path: "/fr/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___it",
    path: "/it/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___he",
    path: "/he/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___hu",
    path: "/hu/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___nl",
    path: "/nl/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-login___uk-UA",
    path: "/uk-UA/auth/login",
    meta: loginVaZcCAuvtcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/login.vue")
  },
  {
    name: "auth-logout___en___default",
    path: "/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___en",
    path: "/en/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___es",
    path: "/es/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___de",
    path: "/de/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___fr",
    path: "/fr/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___it",
    path: "/it/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___he",
    path: "/he/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___hu",
    path: "/hu/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___nl",
    path: "/nl/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___uk-UA",
    path: "/uk-UA/auth/logout",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/logout.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___en___default",
    path: "/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___en",
    path: "/en/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___es",
    path: "/es/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___de",
    path: "/de/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___fr",
    path: "/fr/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___it",
    path: "/it/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___he",
    path: "/he/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___hu",
    path: "/hu/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___nl",
    path: "/nl/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot-mail-sent___uk-UA",
    path: "/uk-UA/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sent4fpabxYHqcMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot-mail-sent.vue")
  },
  {
    name: "auth-password-forgot___en___default",
    path: "/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___en",
    path: "/en/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___es",
    path: "/es/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___de",
    path: "/de/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___fr",
    path: "/fr/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___it",
    path: "/it/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___he",
    path: "/he/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___hu",
    path: "/hu/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___nl",
    path: "/nl/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-forgot___uk-UA",
    path: "/uk-UA/auth/password/forgot",
    meta: forgotziLimcxMDoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-reset___en___default",
    path: "/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___en",
    path: "/en/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___es",
    path: "/es/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___de",
    path: "/de/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___fr",
    path: "/fr/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___it",
    path: "/it/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___he",
    path: "/he/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___hu",
    path: "/hu/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___nl",
    path: "/nl/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-password-reset___uk-UA",
    path: "/uk-UA/auth/password/reset",
    meta: resetRRW4b8qiBNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/password/reset.vue")
  },
  {
    name: "auth-redirect___en___default",
    path: "/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___en",
    path: "/en/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___es",
    path: "/es/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___de",
    path: "/de/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___fr",
    path: "/fr/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___it",
    path: "/it/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___he",
    path: "/he/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___hu",
    path: "/hu/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___nl",
    path: "/nl/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-redirect___uk-UA",
    path: "/uk-UA/auth/redirect",
    meta: redirectjpC1rmUVnWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/redirect.vue")
  },
  {
    name: "auth-sign-up___en___default",
    path: "/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___en",
    path: "/en/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___es",
    path: "/es/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___de",
    path: "/de/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___fr",
    path: "/fr/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___it",
    path: "/it/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___he",
    path: "/he/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___hu",
    path: "/hu/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___nl",
    path: "/nl/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up___uk-UA",
    path: "/uk-UA/auth/sign-up",
    meta: indexCO2vqo93TEMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up-local___en___default",
    path: "/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___en",
    path: "/en/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___es",
    path: "/es/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___de",
    path: "/de/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___fr",
    path: "/fr/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___it",
    path: "/it/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___he",
    path: "/he/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___hu",
    path: "/hu/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___nl",
    path: "/nl/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-local___uk-UA",
    path: "/uk-UA/auth/sign-up/local",
    meta: localZKvONDCgOxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/auth/pages/auth/sign-up/local.vue")
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___en___default",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___en___default",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___en___default",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___en___default",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___en___default",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___en___default",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___en___default",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/en/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___en",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___en",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___en",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___en",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___en",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___en",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___en",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/es/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___es",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___es",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___es",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___es",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___es",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___es",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___es",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/de/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___de",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___de",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___de",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___de",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___de",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___de",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___de",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/fr/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___fr",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___fr",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___fr",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___fr",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___fr",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___fr",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___fr",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/it/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___it",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___it",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___it",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___it",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___it",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___it",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___it",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/he/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___he",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___he",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___he",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___he",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___he",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___he",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___he",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/hu/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___hu",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___hu",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___hu",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___hu",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___hu",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___hu",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___hu",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/nl/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___nl",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___nl",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___nl",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___nl",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___nl",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___nl",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___nl",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: _91eventId_93lBz1DsX54RMeta?.name,
    path: "/uk-UA/events/:eventId()",
    meta: _91eventId_93lBz1DsX54RMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId].vue"),
    children: [
  {
    name: "events-eventId-slug___uk-UA",
    path: ":slug(.*)*",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/[...slug].vue")
  },
  {
    name: "events-eventId-athlete-stats___uk-UA",
    path: "athlete-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/athlete-stats.vue")
  },
  {
    name: "events-eventId-clips-type___uk-UA",
    path: "clips/:type()",
    meta: _91type_935O9nPc56d6Meta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/[type].vue")
  },
  {
    name: "events-eventId-clips___uk-UA",
    path: "clips",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/clips/index.vue")
  },
  {
    name: "events-eventId___uk-UA",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-play-by-play___uk-UA",
    path: "play-by-play",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/play-by-play.vue")
  },
  {
    name: "events-eventId-team-stats___uk-UA",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/[eventId]/team-stats.vue")
  }
]
  },
  {
    name: "events-edit-eventId___en___default",
    path: "/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___en",
    path: "/en/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___es",
    path: "/es/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___de",
    path: "/de/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___fr",
    path: "/fr/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___it",
    path: "/it/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___he",
    path: "/he/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___hu",
    path: "/hu/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___nl",
    path: "/nl/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-edit-eventId___uk-UA",
    path: "/uk-UA/events/edit/:eventId()",
    meta: _91eventId_93I5RZc1uxsuMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/edit/[eventId].vue")
  },
  {
    name: "events-editor-eventId___en___default",
    path: "/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___en",
    path: "/en/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___es",
    path: "/es/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___de",
    path: "/de/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___fr",
    path: "/fr/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___it",
    path: "/it/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___he",
    path: "/he/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___hu",
    path: "/hu/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___nl",
    path: "/nl/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events-editor-eventId___uk-UA",
    path: "/uk-UA/events/editor/:eventId()",
    meta: _91eventId_937z94iddqnxMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/editor/[eventId].vue")
  },
  {
    name: "events___en___default",
    path: "/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/en/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___es",
    path: "/es/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___de",
    path: "/de/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___fr",
    path: "/fr/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___it",
    path: "/it/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___he",
    path: "/he/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___hu",
    path: "/hu/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___nl",
    path: "/nl/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events___uk-UA",
    path: "/uk-UA/events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/index.vue")
  },
  {
    name: "events-list-type___en___default",
    path: "/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___en",
    path: "/en/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___es",
    path: "/es/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___de",
    path: "/de/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___fr",
    path: "/fr/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___it",
    path: "/it/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___he",
    path: "/he/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___hu",
    path: "/hu/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___nl",
    path: "/nl/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "events-list-type___uk-UA",
    path: "/uk-UA/events/list/:type()",
    meta: _91type_93y6MOjYxp3hMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/events/list/[type].vue")
  },
  {
    name: "highlights___en___default",
    path: "/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___en",
    path: "/en/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___es",
    path: "/es/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___de",
    path: "/de/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___fr",
    path: "/fr/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___it",
    path: "/it/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___he",
    path: "/he/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___hu",
    path: "/hu/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___nl",
    path: "/nl/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "highlights___uk-UA",
    path: "/uk-UA/highlights",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/highlights.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___he",
    path: "/he",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___hu",
    path: "/hu",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: "index___uk-UA",
    path: "/uk-UA",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/common/pages/index.vue")
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___en___default",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___en___default",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___en___default",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___en___default",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___en___default",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___en___default",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/en/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___en",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___en",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___en",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___en",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___en",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___en",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/es/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___es",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___es",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___es",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___es",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___es",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___es",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/de/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___de",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___de",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___de",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___de",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___de",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___de",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/fr/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___fr",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___fr",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___fr",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___fr",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___fr",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___fr",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/it/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___it",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___it",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___it",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___it",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___it",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___it",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/he/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___he",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___he",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___he",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___he",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___he",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___he",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/hu/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___hu",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___hu",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___hu",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___hu",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___hu",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___hu",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/nl/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___nl",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___nl",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___nl",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___nl",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___nl",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___nl",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: _91leagueId_93Sx9bB2PLpLMeta?.name,
    path: "/uk-UA/leagues/:leagueId()",
    meta: _91leagueId_93Sx9bB2PLpLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId].vue"),
    children: [
  {
    name: "leagues-leagueId-athletes___uk-UA",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/athletes.vue")
  },
  {
    name: "leagues-leagueId-events___uk-UA",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/events.vue")
  },
  {
    name: "leagues-leagueId___uk-UA",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/index.vue")
  },
  {
    name: "leagues-leagueId-leaderboards___uk-UA",
    path: "leaderboards",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/leaderboards.vue")
  },
  {
    name: "leagues-leagueId-standings___uk-UA",
    path: "standings",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/standings.vue")
  },
  {
    name: "leagues-leagueId-teams___uk-UA",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/[leagueId]/teams.vue")
  }
]
  },
  {
    name: "leagues-edit-leagueId___en___default",
    path: "/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___en",
    path: "/en/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___es",
    path: "/es/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___de",
    path: "/de/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___fr",
    path: "/fr/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___it",
    path: "/it/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___he",
    path: "/he/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___hu",
    path: "/hu/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___nl",
    path: "/nl/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-edit-leagueId___uk-UA",
    path: "/uk-UA/leagues/edit/:leagueId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/edit/[leagueId].vue")
  },
  {
    name: "leagues-events-type___en___default",
    path: "/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___en",
    path: "/en/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___es",
    path: "/es/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___de",
    path: "/de/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___fr",
    path: "/fr/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___it",
    path: "/it/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___he",
    path: "/he/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___hu",
    path: "/hu/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___nl",
    path: "/nl/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues-events-type___uk-UA",
    path: "/uk-UA/leagues/events/:type()",
    meta: _91type_93ehiCoF9EqYMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/events/[type].vue")
  },
  {
    name: "leagues___en___default",
    path: "/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___en",
    path: "/en/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___es",
    path: "/es/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___de",
    path: "/de/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___fr",
    path: "/fr/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___it",
    path: "/it/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___he",
    path: "/he/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___hu",
    path: "/hu/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___nl",
    path: "/nl/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues___uk-UA",
    path: "/uk-UA/leagues",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/index.vue")
  },
  {
    name: "leagues-new___en___default",
    path: "/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___en",
    path: "/en/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___es",
    path: "/es/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___de",
    path: "/de/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___fr",
    path: "/fr/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___it",
    path: "/it/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___he",
    path: "/he/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___hu",
    path: "/hu/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___nl",
    path: "/nl/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: "leagues-new___uk-UA",
    path: "/uk-UA/leagues/new",
    meta: newlNFsjUzy1tMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/leagues/pages/leagues/new.vue")
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___en___default",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___en___default",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___en___default",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/en/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___en",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___en",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___en",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/es/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___es",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___es",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___es",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/de/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___de",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___de",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___de",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/fr/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___fr",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___fr",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___fr",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/it/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___it",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___it",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___it",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/he/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___he",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___he",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___he",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/hu/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___hu",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___hu",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___hu",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/nl/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___nl",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___nl",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___nl",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: _91organizationId_93QqCaZHh2P9Meta?.name,
    path: "/uk-UA/organizations/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-admins___uk-UA",
    path: "admins",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/admins.vue")
  },
  {
    name: "organizations-organizationId___uk-UA",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-teams___uk-UA",
    path: "teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/[organizationId]/teams.vue")
  }
]
  },
  {
    name: "organizations-edit-organizationId___en___default",
    path: "/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___en",
    path: "/en/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___es",
    path: "/es/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___de",
    path: "/de/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___fr",
    path: "/fr/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___it",
    path: "/it/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___he",
    path: "/he/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___hu",
    path: "/hu/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___nl",
    path: "/nl/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations-edit-organizationId___uk-UA",
    path: "/uk-UA/organizations/edit/:organizationId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/edit/[organizationId].vue")
  },
  {
    name: "organizations___en___default",
    path: "/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___en",
    path: "/en/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___es",
    path: "/es/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___de",
    path: "/de/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___fr",
    path: "/fr/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___it",
    path: "/it/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___he",
    path: "/he/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___hu",
    path: "/hu/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___nl",
    path: "/nl/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations___uk-UA",
    path: "/uk-UA/organizations",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/index.vue")
  },
  {
    name: "organizations-new___en___default",
    path: "/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___en",
    path: "/en/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___es",
    path: "/es/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___de",
    path: "/de/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___fr",
    path: "/fr/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___it",
    path: "/it/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___he",
    path: "/he/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___hu",
    path: "/hu/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___nl",
    path: "/nl/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "organizations-new___uk-UA",
    path: "/uk-UA/organizations/new",
    meta: new9JAs37iSpFMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/organizations/pages/organizations/new.vue")
  },
  {
    name: "practices___en___default",
    path: "/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___en",
    path: "/en/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___es",
    path: "/es/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___de",
    path: "/de/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___fr",
    path: "/fr/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___it",
    path: "/it/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___he",
    path: "/he/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___hu",
    path: "/hu/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___nl",
    path: "/nl/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: "practices___uk-UA",
    path: "/uk-UA/practices",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/practices.vue")
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___en___default",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___en___default",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___en___default",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___en___default",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___en___default",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___en___default",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___en___default",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___en___default",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___en___default",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/en/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___en",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___en",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___en",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___en",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___en",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___en",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___en",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___en",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___en",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/es/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___es",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___es",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___es",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___es",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___es",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___es",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___es",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___es",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___es",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/de/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___de",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___de",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___de",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___de",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___de",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___de",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___de",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___de",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___de",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/fr/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___fr",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___fr",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___fr",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___fr",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___fr",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___fr",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___fr",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___fr",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___fr",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/it/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___it",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___it",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___it",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___it",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___it",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___it",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___it",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___it",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___it",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/he/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___he",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___he",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___he",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___he",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___he",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___he",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___he",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___he",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___he",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/hu/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___hu",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___hu",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___hu",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___hu",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___hu",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___hu",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___hu",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___hu",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___hu",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/nl/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___nl",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___nl",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___nl",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___nl",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___nl",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___nl",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___nl",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___nl",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___nl",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: _91teamId_93X7Ofhqtoa0Meta?.name,
    path: "/uk-UA/teams/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId-athletes___uk-UA",
    path: "athletes",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/athletes.vue")
  },
  {
    name: "teams-teamId-breakdowns-and-services___uk-UA",
    path: "breakdowns-and-services",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/breakdowns-and-services.vue")
  },
  {
    name: "teams-teamId-coaches___uk-UA",
    path: "coaches",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/coaches.vue")
  },
  {
    name: "teams-teamId-events___uk-UA",
    path: "events",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/events.vue")
  },
  {
    name: "teams-teamId___uk-UA",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-members___uk-UA",
    path: "members",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/members.vue")
  },
  {
    name: "teams-teamId-opponents___uk-UA",
    path: "opponents",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/opponents.vue")
  },
  {
    name: "teams-teamId-rosters___uk-UA",
    path: "rosters",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/rosters.vue")
  },
  {
    name: "teams-teamId-team-stats___uk-UA",
    path: "team-stats",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/[teamId]/team-stats.vue")
  }
]
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___en___default",
    path: "/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___en",
    path: "/en/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___es",
    path: "/es/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___de",
    path: "/de/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___fr",
    path: "/fr/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___it",
    path: "/it/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___he",
    path: "/he/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___hu",
    path: "/hu/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___nl",
    path: "/nl/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-athleteId-edit___uk-UA",
    path: "/uk-UA/teams/athletes/:teamId()/:athleteId()/edit",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/[athleteId]/edit.vue")
  },
  {
    name: "teams-athletes-teamId-new___en___default",
    path: "/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___en",
    path: "/en/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___es",
    path: "/es/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___de",
    path: "/de/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___fr",
    path: "/fr/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___it",
    path: "/it/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___he",
    path: "/he/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___hu",
    path: "/hu/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___nl",
    path: "/nl/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-athletes-teamId-new___uk-UA",
    path: "/uk-UA/teams/athletes/:teamId()/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/athletes/[teamId]/new.vue")
  },
  {
    name: "teams-edit-teamId___en___default",
    path: "/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___en",
    path: "/en/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___es",
    path: "/es/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___de",
    path: "/de/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___fr",
    path: "/fr/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___it",
    path: "/it/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___he",
    path: "/he/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___hu",
    path: "/hu/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___nl",
    path: "/nl/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-edit-teamId___uk-UA",
    path: "/uk-UA/teams/edit/:teamId()",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/edit/[teamId].vue")
  },
  {
    name: "teams-events-type___en___default",
    path: "/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___en",
    path: "/en/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___es",
    path: "/es/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___de",
    path: "/de/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___fr",
    path: "/fr/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___it",
    path: "/it/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___he",
    path: "/he/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___hu",
    path: "/hu/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___nl",
    path: "/nl/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-events-type___uk-UA",
    path: "/uk-UA/teams/events/:type()",
    meta: _91type_93LUAjZU3uAPMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/events/[type].vue")
  },
  {
    name: "teams-import-teamId-replay___en___default",
    path: "/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___en",
    path: "/en/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___es",
    path: "/es/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___de",
    path: "/de/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___fr",
    path: "/fr/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___it",
    path: "/it/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___he",
    path: "/he/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___hu",
    path: "/hu/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___nl",
    path: "/nl/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams-import-teamId-replay___uk-UA",
    path: "/uk-UA/teams/import/:teamId()/replay",
    meta: replay52AmooCJUmMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/import/[teamId]/replay.vue")
  },
  {
    name: "teams___en___default",
    path: "/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___en",
    path: "/en/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___es",
    path: "/es/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___de",
    path: "/de/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___fr",
    path: "/fr/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___it",
    path: "/it/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___he",
    path: "/he/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___hu",
    path: "/hu/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___nl",
    path: "/nl/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams___uk-UA",
    path: "/uk-UA/teams",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/index.vue")
  },
  {
    name: "teams-join___en___default",
    path: "/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___en",
    path: "/en/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___es",
    path: "/es/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___de",
    path: "/de/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___fr",
    path: "/fr/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___it",
    path: "/it/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___he",
    path: "/he/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___hu",
    path: "/hu/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___nl",
    path: "/nl/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-join___uk-UA",
    path: "/uk-UA/teams/join",
    meta: joinGSl4M0NUkWMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/join.vue")
  },
  {
    name: "teams-new___en___default",
    path: "/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___en",
    path: "/en/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___es",
    path: "/es/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___de",
    path: "/de/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___fr",
    path: "/fr/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___it",
    path: "/it/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___he",
    path: "/he/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___hu",
    path: "/hu/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___nl",
    path: "/nl/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-new___uk-UA",
    path: "/uk-UA/teams/new",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/new.vue")
  },
  {
    name: "teams-upload-teamId___en___default",
    path: "/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___en",
    path: "/en/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___es",
    path: "/es/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___de",
    path: "/de/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___fr",
    path: "/fr/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___it",
    path: "/it/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___he",
    path: "/he/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___hu",
    path: "/hu/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___nl",
    path: "/nl/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId___uk-UA",
    path: "/uk-UA/teams/upload/:teamId()",
    meta: indexpIS20iqyhNMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/index.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___en___default",
    path: "/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___en",
    path: "/en/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___es",
    path: "/es/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___de",
    path: "/de/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___fr",
    path: "/fr/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___it",
    path: "/it/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___he",
    path: "/he/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___hu",
    path: "/hu/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___nl",
    path: "/nl/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: "teams-upload-teamId-pixellot___uk-UA",
    path: "/uk-UA/teams/upload/:teamId()/pixellot",
    meta: pixellotdd97YoYzlLMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/teams/pages/teams/upload/[teamId]/pixellot.vue")
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___en___default",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___en___default",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/en/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___en",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___en",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/es/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___es",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___es",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/de/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___de",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___de",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/fr/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___fr",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___fr",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/it/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___it",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___it",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/he/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___he",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___he",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/hu/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___hu",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___hu",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/nl/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___nl",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___nl",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: contentOYFEtsHOAlMeta?.name,
    path: "/uk-UA/user/content",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content.vue"),
    children: [
  {
    name: "user-content-favorites___uk-UA",
    path: "favorites",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/favorites.vue")
  },
  {
    name: "user-content___uk-UA",
    path: "",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/content/index.vue")
  }
]
  },
  {
    name: "user-profile___en___default",
    path: "/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___en",
    path: "/en/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___es",
    path: "/es/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___de",
    path: "/de/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___fr",
    path: "/fr/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___it",
    path: "/it/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___he",
    path: "/he/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___hu",
    path: "/hu/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___nl",
    path: "/nl/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-profile___uk-UA",
    path: "/uk-UA/user/profile",
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/profile.vue")
  },
  {
    name: "user-videos-type___en___default",
    path: "/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___en",
    path: "/en/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___es",
    path: "/es/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___de",
    path: "/de/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___fr",
    path: "/fr/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___it",
    path: "/it/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___he",
    path: "/he/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___hu",
    path: "/hu/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___nl",
    path: "/nl/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  },
  {
    name: "user-videos-type___uk-UA",
    path: "/uk-UA/user/videos/:type()",
    meta: _91type_93gXvKZpR5ZoMeta || {},
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/app/videos/pages/user/videos/[type].vue")
  }
]