import { StorageSerializers, useStorage } from "@vueuse/core";
import type { ILocation } from "~/types";

export function useLocation(): Promise<ILocation> {
  const route = useRoute();
  const locationCache = useStorage<ILocation>("location", null, undefined, { serializer: StorageSerializers.object });

  if (route.query?.g && route?.query?.r) {
    locationCache.value = { country: route.query.g as string, region: route.query.r as string };
    return Promise.resolve(locationCache.value);
  }

  if (locationCache.value)
    return Promise.resolve(locationCache.value);

  return useAPI<ILocation>("/api/v1/payment/users/signup/hints")
    .then((response) => {
      locationCache.value = response;
      return response;
    });
}
