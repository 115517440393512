import type { RouteLocationNormalized, LocationQueryRaw } from "#vue-router";

export default defineNuxtPlugin(() => {
  const user = useUser();

  function navigateToLogin(route: RouteLocationNormalized, queryParams?: LocationQueryRaw) {
    if (route.path == "/auth/login") {
      return Promise.resolve();
    }

    return navigateTo({ path: "/auth/login", query: { ...queryParams, redirectUrl: route.fullPath } });
  }

  addRouteMiddleware(
    "auth",
    (to) => {
      const isLoggedIn = Boolean(user.value);
      const isGuest = !isLoggedIn;
      const isGuestAllowed = to.meta.layout === "auth" || to.meta.allow_guest;

      if (isGuest && !isGuestAllowed && to.query.playlistId) {
        return navigateToLogin(to, { playlistPermissionError: "true" });
      }

      if (isGuest && !isGuestAllowed) {
        return navigateToLogin(to);
      }
    },
    { global: true },
  );
});
