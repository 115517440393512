import revive_payload_client_icjwWOOUte from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._212e2606cca0637fdea856835b76a213/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HS4uTRgrcg from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._212e2606cca0637fdea856835b76a213/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ryf1v4ecqJ from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._212e2606cca0637fdea856835b76a213/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_pOAJpsi8S6 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._212e2606cca0637fdea856835b76a213/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3Xcm4GsGqX from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._212e2606cca0637fdea856835b76a213/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_ZedeWZBNjf from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._212e2606cca0637fdea856835b76a213/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_Bey0zzX5yh from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4._212e2606cca0637fdea856835b76a213/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_j7VH8mWeOk from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_KdyCKuhn0F from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.17.0_jiti@2.4.2__magicast@0.3.5_ro_f8245da92f2769119019716566e9a631/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_XS0ZuGnpTq from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.17.0_jiti@2.4.2__magicast@0.3.5_ro_f8245da92f2769119019716566e9a631/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_CS8Nw8Hh9h from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_RodqKbTAEC from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-open-fetch@0.2.2_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-open-fetch/dist/runtime/plugin.mjs";
import firebase_client_Hvhq3Br38I from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/plugins/firebase.client.ts";
import middleware_global_Op56gcvAu7 from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/plugins/middleware.global.ts";
import auto_animate_client_tPhlykCQkd from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/auto-animate.client.ts";
import datadog_client_HFA3DI4IOm from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/datadog.client.ts";
import floating_vue_XWMRPwzpDx from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/floating-vue.ts";
import google_analytics_client_tAORyHKtke from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/google-analytics.client.ts";
import modals_W5cFL67p7j from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/modals.ts";
import openFetch_xZPyl0lGIu from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/openFetch.ts";
export default [
  revive_payload_client_icjwWOOUte,
  unhead_HS4uTRgrcg,
  router_ryf1v4ecqJ,
  payload_client_pOAJpsi8S6,
  navigation_repaint_client_3Xcm4GsGqX,
  chunk_reload_client_ZedeWZBNjf,
  components_plugin_zlvi6dcIsi,
  prefetch_client_Bey0zzX5yh,
  plugin_j7VH8mWeOk,
  switch_locale_path_ssr_KdyCKuhn0F,
  i18n_XS0ZuGnpTq,
  plugin_client_CS8Nw8Hh9h,
  plugin_RodqKbTAEC,
  firebase_client_Hvhq3Br38I,
  middleware_global_Op56gcvAu7,
  auto_animate_client_tPhlykCQkd,
  datadog_client_HFA3DI4IOm,
  floating_vue_XWMRPwzpDx,
  google_analytics_client_tAORyHKtke,
  modals_W5cFL67p7j,
  openFetch_xZPyl0lGIu
]