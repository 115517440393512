import type {
  IResponseClip,
  IResponseEvent,
  IResponseHighlight,
  IResponseMBEEvent,
  IResponsePlayerHighlight,
  TBasicVideoURL,
  TBasicVideoUrls,
  TBasicVideoUrlsMap,
} from "~/types";

function formatEventVideoUrlsMap(data: TBasicVideoUrls): TBasicVideoUrlsMap {
  return new Map(data.map(item => [item.type, item]));
}

export function formatEventVideoUrls(data: IResponseEvent): TBasicVideoUrlsMap {
  if (data.attributes.videosList.length) {
    const urls: TBasicVideoUrls = data.attributes.videosList
      .map((video) => {
        const videoURLItem: TBasicVideoURL = {
          url: video.url,
          type: video.type,
          thumbnails_url: video.previewThumbnail,
          duration: video.duration,
          // @ts-expect-error TODO: Add when support will be added on BE
          mp4_url: video.downloadURL,
        };

        if (videoURLItem.type === "hd") {
          videoURLItem.mp4_url = data.attributes.urls?.hdMP4;
        }
        else if (videoURLItem.type === "pano") {
          videoURLItem.mp4_url = data.attributes.urls?.panoMP4;
        }

        return videoURLItem;
      })
      .filter(src => src.url);

    return formatEventVideoUrlsMap(urls);
  }

  const urls = [
    {
      url: data.attributes.urls?.hd,
      type: "hd" as const,
      thumbnails_url: data.attributes.thumbnails.hd,
    },
    {
      url: data.attributes.urls?.pano,
      type: "pano" as const,
      thumbnails_url: data.attributes.previewThumbnails.pano,
    },
  ].filter(src => src.url) as TBasicVideoUrls;

  return formatEventVideoUrlsMap(urls);

  // return data.attributes.videosList.map((video) => ({}));
  // return {
  //   hd:
  //     data.attributes.urls?.hd ||
  //     data.attributes.urls?.["1st"] ||
  //     data.attributes.urls?.["2nd"] ||
  //     data.attributes.urls?.["3rd"] ||
  //     data.attributes.urls?.["4th"] ||
  //     data.attributes.urls?.angle0 ||
  //     data.attributes.urls?.angle1 ||
  //     data.attributes.urls?.angle2 ||
  //     data.attributes.urls?.angle3 ||
  //     data.attributes.urls?.endzone ||
  //     data.attributes.urls?.goal1 ||
  //     data.attributes.urls?.goal2 ||
  //     data.attributes.urls?.side ||
  //     data.attributes.urls?.sideline ||
  //     data.attributes.urls?.tight ||
  //     data.attributes.urls?.wide,
  //   hdThumbnails: data.attributes.previewThumbnails.hd,
  //   hdMP4: data.attributes.urls?.hdMP4,
  //   pano: data.attributes.urls?.pano,
  //   panoMP4: data.attributes.urls?.panoMP4,
  //   panoThumbnails: data.attributes.previewThumbnails.pano,
  //   tactical: data.attributes.urls?.tactical,
  //   tacticalMP4: data.attributes.urls?.tacticalMP4,
  //   tacticalThumbnails: undefined, // TODO: Add when support will be added
  // };
}
export function formatMBEEventVideoUrls(data: IResponseMBEEvent): TBasicVideoUrlsMap {
  const urls = [
    {
      url: data.attributes.urls?.hd,
      type: "hd" as const,
      thumbnails_url: data.attributes.urls?.hdThumbnailUrl,
    },
    {
      url: data.attributes.urls?.pano,
      type: "pano" as const,
      thumbnails_url: data.attributes.urls?.panoThumbnailUrl,
    },
  ].filter(src => src.url) as TBasicVideoUrls;

  return formatEventVideoUrlsMap(urls);
}
export function formatClipVideoUrls(data: IResponseClip): TBasicVideoUrlsMap {
  const urls: TBasicVideoUrls = [
    {
      url: data.attributes.urls.clip!,
      type: "hd" as const,
      mp4_url: data.attributes.downloadUrls.clip,
    },
  ].filter(src => src.url);
  return formatEventVideoUrlsMap(urls);
}
export function formatHighlightVideoUrls(data: IResponseHighlight): TBasicVideoUrlsMap {
  const urls: TBasicVideoUrls = [
    {
      url: data.attributes.urls.highlight,
      type: "hd" as const,
      mp4_url: data.attributes.downloadUrls.highlight,
    },
  ].filter(src => src.url);
  return formatEventVideoUrlsMap(urls);
}
export function formatInlineHighlightVideoUrls(
  data: IResponseEvent["attributes"]["highlights"][0],
): TBasicVideoUrlsMap {
  const urls: TBasicVideoUrls = [
    {
      url: data.url,
      type: "hd" as const,
      mp4_url: data.downloadUrl,
    },
  ].filter(src => src.url);
  return formatEventVideoUrlsMap(urls);
}

export function formatPlayerHighlightVideoUrls(data: IResponsePlayerHighlight): TBasicVideoUrlsMap {
  const urls: TBasicVideoUrls = [
    {
      url: data.attributes.urls.highlight,
      type: "hd" as const,
      mp4_url: data.attributes.downloadUrls.highlight,
      thumbnails_url: null,
    },
  ].filter(src => src.url);
  return formatEventVideoUrlsMap(urls);
}
