import sleep from "~/common/helpers/sleep";
import {
  formatScoreStats,
  formatGameStats,
  formatAthleteStats,
} from "~/videos/helpers/format-event";
import getSharedHeaders from "~/common/helpers/get-headers";
import type {
  IEventAthleteStats,
  IEventGameStats,
  IEventScoreStats,
  IMatchRosterLeaders,
  IResponseEventStatsRosters,
  IResponseEventStatsScores,
  IResponseEventStatsTeams,
} from "~/types";

export const getCachedEventGameStatsById = useMemoize(
  (
    eventId: string,
    options?: {
      query: Partial<{ format: "sheet"; sheetId: number }>;
      accessToken?: string;
      locale?: string;
    },
  ): Promise<IEventGameStats> => getEventGameStatsById(eventId, options),
);

export async function getEventGameStatsById(
  eventId: string,
  options?: {
    query: Partial<{ format: "sheet"; sheetId: number }>;
    accessToken?: string;
    locale?: string;
  },
): Promise<IEventGameStats> {
  if (eventId === "demo") {
    await sleep(2000);

    return $fetch<IResponseEventStatsTeams>(
      "/mocks/data/event/123/stats_game.json",
    ).then(formatGameStats);
  }

  return useAPI<IResponseEventStatsTeams>(
    `/api/v1/events/${eventId}/statistics/teams`,
    {
      headers: getSharedHeaders(options),
      ...(options?.query && { query: options.query }),
    },
  ).then(formatGameStats);
}

export async function getEventScoreStatsById(
  eventId: string,
  options?: { accessToken?: string; locale?: string },
): Promise<IEventScoreStats> {
  if (eventId === "demo") {
    await new Promise(resolve => setTimeout(() => resolve(true), 2000));

    return $fetch<IResponseEventStatsScores>(
      "/mocks/data/event/123/stats_score.json",
    ).then(formatScoreStats);
  }

  return useAPI<IResponseEventStatsScores>(
    `/api/v1/events/${eventId}/statistics/scores`,
    {
      headers: getSharedHeaders(options),
    },
  ).then(formatScoreStats);
}

export const getCachedEventAthletesStatsById = useMemoize(
  (
    eventId: string,
    options?: {
      query: Partial<{ format: "sheet"; sheetId: number }>;
      accessToken?: string;
      locale?: string;
    },
  ): Promise<IEventAthleteStats> => getEventAthletesStatsById(eventId, options),
);

export async function getEventAthletesStatsById(
  eventId: string,
  options?: {
    query: Partial<{ format: "sheet"; sheetId: number }>;
    accessToken?: string;
    locale?: string;
  },
): Promise<IEventAthleteStats> {
  if (eventId === "demo") {
    await new Promise(resolve => setTimeout(() => resolve(true), 2000));

    return $fetch<IResponseEventStatsRosters>(
      "/mocks/data/event/123/stats_athletes.json",
    ).then(formatAthleteStats);
  }

  return useAPI<IResponseEventStatsRosters>(
    `/api/v1/events/${eventId}/statistics/rosters`,
    {
      headers: getSharedHeaders(options),
      ...(options?.query && { query: options.query }),
    },
  ).then(formatAthleteStats);
}

export const getCachedTeamStatsTypeOptionsByEventId = useMemoize(
  (
    eventId: string,
    options?: { accessToken?: string; locale?: string },
  ): Promise<{ id: number; label: string; value: number }[]> =>
    getTeamStatsTypeOptionsByEventId(eventId, options),
);

export async function getTeamStatsTypeOptionsByEventId(
  eventId: string,
  options?: { accessToken?: string; locale?: string },
): Promise<{ id: number; label: string; value: number }[]> {
  if (eventId === "demo")
    return await new Promise(resolve => setTimeout(() => resolve([]), 2000));

  // type: "rosters" | "teams"
  // return useAPI<IResponseEventStatsRosters>(`/v1/events/${eventId}/statistics/:type/sheets`, {
  return useAPI<{
    data: {
      id: number;
      type: "sheet";
      attributes: { name: string; type: "teams" };
    }[];
  }>(`/api/v1/events/${eventId}/statistics/teams/sheets`, {
    headers: getSharedHeaders(options),
  }).then(res =>
    res.data.map(i => ({ id: i.id, label: i.attributes.name, value: i.id })),
  );
}

export const getCachedAthleteStatsTypeOptionsByEventId = useMemoize(
  (
    eventId: string,
    options?: { accessToken?: string; locale?: string },
  ): Promise<{ id: number; label: string; value: number }[]> =>
    getAthleteStatsTypeOptionsByEventId(eventId, options),
);

export async function getAthleteStatsTypeOptionsByEventId(
  eventId: string,
  options?: { accessToken?: string; locale?: string },
): Promise<{ id: number; label: string; value: number }[]> {
  if (eventId === "demo")
    return await new Promise(resolve => setTimeout(() => resolve([]), 2000));

  // type: "rosters" | "teams"
  // return useAPI<IResponseEventStatsRosters>(`/v1/events/${eventId}/statistics/:type/sheets`, {
  return useAPI<{
    data: {
      id: number;
      type: "sheet";
      attributes: { name: string; type: "rosters" };
    }[];
  }>(`/api/v1/events/${eventId}/statistics/rosters/sheets`, {
    headers: getSharedHeaders(options),
  }).then(res =>
    res.data.map(i => ({ id: i.id, label: i.attributes.name, value: i.id })),
  );
}

export async function getMatchLeadersByEventId(
  eventId: string,
  options?: { accessToken?: string; locale?: string },
): Promise<{ data: IMatchRosterLeaders }> {
  return useAPI(`api/v2/events/${eventId}/statistics/match-roster-leaders`, {
    headers: getSharedHeaders(options),
  });
}
