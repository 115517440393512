import type { IResponsePlaylist, IResponsePlaylistItem, IPlaylistItem, IPlaylist } from "~/types";
import formatEvent from "~/videos/helpers/format-event";

export function formatPlaylist(item: IResponsePlaylist): IPlaylist {
  return {
    id: item.id,
    name: item.attributes.name || "-",
    type: item.attributes.type || "private",
    size: item.attributes.size || 0,
    source_type: "playlist",
    is_favorite: Boolean(item.relationships?.favorite),
    favoriteId: item.relationships?.favorite?.id,
    permissions: {
      canEdit: item.meta?.permissions?.includes("write") || false,
      canDelete: item.meta?.permissions?.includes("delete") || false,
      canAddToFavorites: item.meta?.permissions?.includes("favorite") || false,
    },
  };
}

export function formatPlaylistItem(item: IResponsePlaylistItem): IPlaylistItem {
  return {
    id: item.id,
    type: item.type,
    title: item.attributes.name || "",
    playerNumber: item.attributes.player || "",
    startTime: item.attributes.startTime,
    endTime: item.attributes.endTime,
    playlist_source: formatEvent(item.attributes.source),
    permissions: {
      canEdit: item.meta.permissions?.includes("write") || false,
      canDelete: item.meta.permissions?.includes("delete") || false,
    },
  };
}

export function formatClickableStatToSystemPlaylist(item: { id: string; name: string }): IPlaylist {
  return {
    id: item.id,
    type: "system",
    name: item.name || "",
    size: 0,
    source_type: "playlist",
    is_favorite: false,
    permissions: {
      canEdit: false,
      canDelete: false,
      canAddToFavorites: false,
    },
  };
}
