export const inputVariants = {
  "default": "u-input-default",
  "filled": "u-input-filled",
  "filled-dark": "u-input-filled-dark",
  "blurry": "u-input-blurry",
  "inline": "u-input-inline",
  "none": "u-input-none",
};
export const inputSizes = {
  sm: "u-input-sm",
  md: "u-input-md",
  lg: "u-input-lg",
};
