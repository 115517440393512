import type { ISelectOption } from "@/common/components/R/Select/Select.vue";
import type { ITeam, TOrganizationType } from "~/types";

type TGenderType = "youth" | "adult";

const youthOrganizations: TOrganizationType[] = ["youth", "school"];

function getGenderType(organization_type?: TOrganizationType): TGenderType {
  if (!organization_type) return "youth";
  if (youthOrganizations.includes(organization_type)) return "youth";
  return "adult";
}

function getLabel(gender: ITeam["gender"], type?: TGenderType, t?: (key: string) => string): string {
  if (!gender || !type || !t) return "N/A";

  const genderType = type || "youth";
  return t(`labels.gender_types.${genderType}.${gender}`);
}

export function getGenderLabel(gender: ITeam["gender"], organization_type?: TOrganizationType, t?: (key: string) => string): string {
  if (!gender) return "N/A";

  const type = getGenderType(organization_type);
  return getLabel(gender, type, t);
}

export function getGenderOptions(organization_type?: TOrganizationType, t?: (key: string) => string): ISelectOption[] {
  const type = getGenderType(organization_type);
  return [
    { label: getLabel("men", type, t), value: "men" },
    { label: getLabel("women", type, t), value: "women" },
    { label: getLabel("coed", type, t), value: "coed" },
  ];
}
