import type { FeatureFlagObject } from "~/account/types/feature-flags";

export const getFeatureFlags = async () => {
  const runtimeConfig = useRuntimeConfig();

  const features = await $fetch<FeatureFlagObject>("static/internal/play-fe-features/play-features.json", {
    baseURL: runtimeConfig.public.contentBaseUrl,
    cache: "no-cache",
  });

  return features;
};

export const updateFeatureFlags = async (featuresBody: FeatureFlagObject) => {
  await useAPI("api/v1/internal/features/flags", { method: "PUT", body: JSON.stringify(featuresBody) });
};

export const getCachedFeatureFlags = useMemoize(getFeatureFlags);
