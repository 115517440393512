import type { MaybeRefOrGetter } from "@vueuse/shared";

export function useAudio(audioSource: MaybeRefOrGetter<string>) {
  const audioSrc = resolveRef(audioSource);
  const isPlaying = ref<boolean>(false);
  const audioPlayer = ref<HTMLAudioElement | null>(null);

  function play() {
    if (!audioPlayer.value)
      return;
    audioPlayer.value?.play();
    isPlaying.value = true;
  }

  function pause() {
    if (!audioPlayer.value)
      return;
    audioPlayer.value?.pause();
    isPlaying.value = false;
  }

  function toggle() {
    return isPlaying.value ? pause() : play();
  }

  function initAudioPlayer() {
    if (audioPlayer.value) {
      pause();
      audioPlayer.value.remove();
      audioPlayer.value = null;
    }

    if (audioSrc.value)
      audioPlayer.value = new Audio(audioSrc.value);
    else
      console.warn("Can't create an audio player without a valid source!");
  }

  watch(audioSrc, initAudioPlayer, { immediate: true });

  return {
    play,
    pause,
    toggle,
    isPlaying,
  };
}
