<script setup lang="ts">
import type { IBasicVideo, IEvent } from "@/types";
import { MenuItemVideoDownload } from "#components";

const props = defineProps<{
  video: IEvent | IBasicVideo;
}>();
</script>

<template>
  <MenuItemVideoDownload
    v-for="[type] in props.video.urls"
    :key="type"
    :type="type"
    :video="props.video"
  />
</template>
