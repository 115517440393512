<script setup lang="ts">
const user = useUser();
</script>

<template>
  <div
    v-if="user"
    class="mb-1 flex w-full items-start gap-3 border-b border-neutral-light-300 px-4 pb-5 pt-4 dark:border-neutral-dark-900"
  >
    <UAvatar
      :alt="user.fullName || 'Anonymous'"
      :size="40"
      class="u-user-avatar shrink-0"
    />
    <div class="flex flex-col gap-1 overflow-hidden">
      <b
        class="block w-full truncate text-base font-bold leading-5 text-black dark:text-white"
        :title="user.fullName || '---'"
      >
        {{ user.fullName || "---" }}
      </b>
      <span
        class="block w-full truncate text-sm font-medium leading-4 text-neutral-light-800 dark:text-neutral-dark-200"
        :title="user.email || '---'"
      >
        {{ user.email }}
      </span>
    </div>
  </div>
</template>
