<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    text: string;
    lines?: number;
  }>(),
  {
    lines: 1,
  },
);

const label = ref<HTMLElement | null>(null);
const showTooltip = ref<boolean>(false);
const observer = ref<ResizeObserver | null>(null);

watch(() => props.text, determineTooltip, { immediate: true });
watch(label, determineTooltip);

async function determineTooltip() {
  if (label.value) {
    await nextTick();
    showTooltip.value = label.value.clientHeight < label.value.scrollHeight;
  }
}

onMounted(() => {
  if (label.value) {
    observer.value = new ResizeObserver(() => {
      determineTooltip();
    });

    observer.value.observe(label.value);
  }
});

onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect();
    observer.value = null;
  }
});
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <span
    ref="label"
    v-tooltip="showTooltip ? props.text : false"
    class="line-clamp-1 break-all"
    :style="{ '-webkit-line-clamp': props.lines }"
    v-html="props.text"
  />
</template>
