<script lang="ts" setup>
import type { ITeamCardItem } from "./CardUI.vue";
import formatLocation from "~/common/helpers/format-location";
import type { ITeam } from "@/types";

const props = defineProps<{
  team: ITeam;
  modelValue: boolean;
}>();

const { t } = useI18n();
const emit = defineEmits<{
  (event: "close"): void;
  (event: "invite-action", action: "join" | "decline"): void;
}>();

const descriptionItems = computed<ITeamCardItem[]>(() => {
  const genderLabel = getGenderLabel(props.team.gender, props.team.organization?.organization_type, t);
  const levelLabel = props.team.level ? t(`league_levels.${props.team.level}`) : "";

  return [
    { name: t("labels.gender"), value: [genderLabel, levelLabel].filter(Boolean).join(", ") },
    { name: t("labels.location"), value: formatLocation(props.team.location) },
    { name: t("labels.sport_type"), sportType: props.team.sport_type },
  ];
});

function onClose() {
  emit("close");
}

function invitationAction(action: "join" | "decline") {
  emit("invite-action", action);
  onClose();
}
</script>

<template>
  <UModal
    :model-value="modelValue"
    container-class="!w-[30rem]"
    @update:model-value="onClose()"
  >
    <div class="p-4">
      <div class="text-end">
        <UButton
          title="Close"
          variant="link-secondary"
          icon="close"
          class="size-5"
          @click="onClose()"
        />
      </div>
      <div class="flex flex-col gap-6 px-6 pb-6">
        <div class="flex flex-col items-center gap-6">
          <div class="flex size-16 items-center justify-center rounded-full border">
            <UIcon class="size-6 text-neutral-light-800" name="mail" />
          </div>

          <div class="flex flex-col items-center gap-4">
            <p class="text-center text-2xl font-bold dark:text-white">
              {{ t('labels.athlete_invited_to', { name: team.name }) }}
            </p>

            <TeamCardUI
              :title="team.name"
              :items="descriptionItems"
              :logo-url="team.logo_url"
              class="w-[16.5rem] border dark:border-neutral-dark-900 dark:text-white"
            />

            <p class="text-center text-sm font-medium dark:text-white">
              {{ t("labels.athlete_to_join") }}
            </p>
          </div>

          <div class="grid w-full grid-cols-2 items-center justify-center gap-4">
            <UButton variant="secondary" @click="invitationAction('decline')">
              {{ t("labels.decline") }}
            </UButton>
            <UButton @click="invitationAction('join')">
              {{ t('labels.join_the_team') }}
            </UButton>
          </div>
        </div>
      </div>
    </div>
  </UModal>
</template>
