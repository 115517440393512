export default defineNuxtPlugin(() => {
  function onAuthFailure() {
    navigateToLogin({ errorCode: "session-expired" });
  }

  useOpenFetchOptions((globalOptions) => {
    return {
      ...globalOptions,
      onRequest(context) {
        const token = useUserToken().value;

        if (token) {
          if (context.options.headers) {
            context.options.headers.set("Authorization", `Bearer ${token}`);
          }
          else {
            context.options.headers = new Headers();
            context.options.headers.set("Authorization", `Bearer ${token}`);
          }
        }
      },
      onRequestError() {
        // Since we can't access the status code here and check for 401 - we're assuming that any request error is a session error,
        // until we will have a fix by DevOps team for ISTIO service: https://github.com/istio/istio/issues/29425
        onAuthFailure();
      },
      onResponseError({ response }) {
        if (response.status === 401) onAuthFailure();
      },
    };
  });
});
