<script setup lang="ts">
const { t, locale: currentLocale } = useI18n();
const localePath = useLocalePath();

const user = useUser();
const colorMode = useColorMode();
const isFeedbackDrawerOpened = ref(false);
const activeSection = ref<"language" | "appearance" | "">("");

async function signOut() {
  await logoutUser();

  navigateTo("/auth/login/");
}
function openMenuSection(section?: "language" | "appearance") {
  activeSection.value = section || "";
}
</script>

<template>
  <UserMenuDrawerFeedback
    v-if="isFeedbackDrawerOpened"
    @close="isFeedbackDrawerOpened = false"
  />

  <UMenu>
    <template
      v-if="user"
      #trigger="{ toggle }"
    >
      <UButton
        variant="link-secondary"
        @click.stop.prevent="toggle"
      >
        <UAvatar
          :alt="user.fullName || 'Anonymous'"
          :size="40"
          class="u-user-avatar"
        />
      </UButton>
    </template>
    <template #default="{ hide }">
      <div
        v-auto-animate
        class="overflow-hidden bg-white dark:bg-neutral-dark-700 md:w-80"
      >
        <template v-if="activeSection">
          <UserMenuSelectionTheme
            v-if="activeSection === 'appearance'"
            @back="openMenuSection()"
          />
          <UserMenuSelectionLanguage
            v-else-if="activeSection === 'language'"
            @back="openMenuSection()"
          />
          <UListItem
            v-else
            size="lg"
            text="Back"
            :icon="{ name: 'arrow-right', class: 'transform rotate-180 text-black dark:text-white' }"
            @click="openMenuSection()"
          />
        </template>
        <UList v-else>
          <UserMenuUserRow />
          <UListItem
            v-if="user"
            size="md"
            icon="profile"
            :text="t('labels.account_settings')"
            :to="localePath('/account/general/')"
          />
          <UListItem
            :on-click-close-menu="false"
            size="md"
            icon="globe"
            :text="t('labels.selected_language', { value: t(`locales.${currentLocale}`) })"
            trailing-icon="chevron-right-small"
            @click="openMenuSection('language')"
          />
          <UListItem
            :on-click-close-menu="false"
            size="md"
            icon="mode"
            :text="t('labels.selected_appearance', { value: t(`labels.theme_${colorMode.preference}`) })"
            trailing-icon="chevron-right-small"
            @click="openMenuSection('appearance')"
          />
          <UListItem
            size="md"
            icon="info"
            :text="t('labels.help_and_support')"
            href="https://docs.pixellot.tv/portal/en/kb/vidswap/edge"
          />
          <UListItem
            v-if="user"
            size="md"
            icon="feedback"
            :text="t('labels.send_feedback')"
            @click="hide(); isFeedbackDrawerOpened = true;"
          />
          <UListItem
            v-if="user"
            size="md"
            :icon="{ name: 'logout', class: 'text-red-500' }"
            @click="signOut()"
          >
            <span class="text-red-500">Logout</span>
          </UListItem>
        </UList>
      </div>
    </template>
  </UMenu>
</template>
