<script setup lang="ts">
import { twJoin } from "tailwind-merge";
import type { PxlIcon } from "@/common/components/U/Icon";
import { iconBind } from "@/common/components/U/Icon";

const sizes = {
  20: "u-avatar-20",
  32: "u-avatar-32",
  36: "u-avatar-36",
  40: "u-avatar-40",
  56: "u-avatar-56",
  80: "u-avatar-80",
  88: "u-avatar-88",
  114: "u-avatar-114",
  120: "u-avatar-120",
};
const imgSizeMap = {
  20: 20,
  32: 24,
  36: 24,
  40: 24,
  56: 32,
  80: 32,
  88: 56,
  114: 72,
  120: 92,
};
const fitClass = {
  cover: "object-cover",
  contain: "object-contain",
};

const props = withDefaults(
  defineProps<{
    src?: string | boolean | null;
    fallbackSrc?: string | boolean | null;
    fit?: "cover" | "contain";
    alt?: string;
    text?: string | number;
    icon?: PxlIcon;
    size?: keyof typeof sizes;
    padded?: boolean;
    bgColor?: string;
    containerClass?: string;
    textClass?: string;
    placeholderClass?: string;
  }>(),
  {
    fit: "cover",
    src: null,
    size: 32,
    padded: true,
  },
);
const hasError = ref(false);
const fallbackError = ref(false);
const imageUrl = computed(() => (typeof props.src === "boolean" ? null : props.src));
const fallbackUrl = computed(() => (typeof props.fallbackSrc === "boolean" ? null : props.fallbackSrc));
const getInitials = (str: string): string =>
  str
    .split(" ")
    .map(word => word.charAt(0))
    .join("")
    .substring(0, 2);
const isAltShown = computed(() => hasError.value || !imageUrl.value); // is also `true` when only icon or text is shown
const placeholder = computed(() => getInitials(props.alt || ""));

const avatarClass = computed(() => {
  return twJoin("u-avatar", sizes[props.size], isAltShown.value ? "u-avatar-bg-empty" : "u-avatar-bg", props.padded ? "u-avatar-padded" : "");
});

const imageAttrs = computed(() => {
  return {
    class: [props.fit && fitClass[props.fit], "rounded-full"],
    alt: props.alt,
    height: imgSizeMap[props.size],
    width: imgSizeMap[props.size],
  };
});

const styles = computed(() => {
  return props.bgColor ? `background-color: ${props.bgColor};` : undefined;
});

watch(() => props.src, resetError);

function resetError() {
  if (hasError.value) {
    hasError.value = false;
  }
  if (fallbackError.value) {
    fallbackError.value = false;
  }
}
function onError() {
  hasError.value = true;
}
function onFallbackError() {
  fallbackError.value = true;
}
</script>

<template>
  <div
    :class="[avatarClass, containerClass]"
    :style="styles"
  >
    <img
      v-if="imageUrl && !hasError"
      v-bind="imageAttrs"
      :src="imageUrl"
      loading="lazy"
      @error="onError"
    >
    <img
      v-else-if="fallbackUrl && !fallbackError"
      v-bind="imageAttrs"
      :src="fallbackUrl"
      @error="onFallbackError"
    >
    <span
      v-else-if="text"
      :class="textClass"
    >{{ text }}</span>
    <UIcon
      v-else-if="icon"
      v-bind="iconBind(icon)"
    />
    <span v-else-if="placeholder" :class="placeholderClass">{{ placeholder }}</span>
    <slot />
  </div>
</template>
