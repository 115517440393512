import { TeamModalViewInvite } from "#components";
import type { ITeam } from "~/types";

interface UseTeamInvitationsProps {
  inviteAction?: (team: ITeam, action: "join" | "decline") => void;
}

export function useTeamInvitations(options?: UseTeamInvitationsProps) {
  const modal = useModal();
  const isInvitePopupOpened = useSessionStorage("first-team-invitation-popup-opened", false);

  function onInvitationsListLoaded(invitations: (ITeam & { invitationId: string })[]) {
    const canOpenInvitePopup = !isInvitePopupOpened.value && invitations.length > 0;

    if (canOpenInvitePopup) {
      isInvitePopupOpened.value = true;
      openInvitation(invitations[0]);
    }
  }

  function openInvitation(team: ITeam) {
    modal.open(TeamModalViewInvite, {
      "modelValue": true,
      "team": team,
      "onClose": () => modal.close(),
      "onInvite-action": action => options?.inviteAction?.(team, action),
    });
  }

  return {
    isInvitePopupOpened,
    onInvitationsListLoaded,
    openInvitation,
  };
}
