<script setup lang="ts">
import type { TSportType } from "@/types";

const props = withDefaults(
  defineProps<{
    sportType?: TSportType;
    itemClass?: string;
  }>(),
  {
    sportType: "other",
  },
);
</script>

<template>
  <div
    class="grid place-items-center rounded-full bg-neutral-light-50 p-[3px] dark:bg-neutral-dark-900"
    :class="itemClass"
  >
    <UIcon
      class="text-[12px] text-neutral-light-700 dark:text-neutral-dark-200"
      :name="props.sportType ? `sports-${props.sportType}` : 'sports-other'"
    />
  </div>
</template>
