<script setup lang="ts">
const props = defineProps<{
  context?: "page" | "event" | "team" | "organization" | "athlete";
  msg?: string;
}>();
const defaultContext = "page";
const context = computed(() => props.context || defaultContext);
const colorMode = useColorMode();
const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div class="flex items-center justify-center py-20">
    <div class="flex flex-col gap-6 text-center">
      <img
        v-if="colorMode?.value === 'light'"
        width="382"
        height="146"
        src="@/common/assets/images/page_not_found_light.png"
      >
      <img
        v-else
        width="382"
        height="146"
        src="@/common/assets/images/page_not_found_dark.png"
      >
      <div class="flex flex-col gap-3 text-black dark:text-white">
        <p class="text-2xl font-bold">
          Oops! {{ `${capitalize(context)} not found` }}
        </p>
        <p class="text-sm font-medium">
          Sorry, we couldn’t find the {{ context }} you are looking for.
        </p>
        <UAlert
          v-if="props.msg"
          color="red"
          :description="props.msg"
        />
      </div>
      <div>
        <UButton
          size="lg"
          label="Go to main page"
          @click="handleError()"
        />
      </div>
    </div>
  </div>
</template>
