<script setup lang="ts">
import type { IPlaylistItem, IPlaylist } from "@/types";
import { formatTime } from "@/videos/helpers/format-time";
import { PLAYLIST_TYPE } from "@/constants";

const props = defineProps<{
  playlist: null | IPlaylist;
  playlistItem: IPlaylistItem;
  activeItem: null | IPlaylistItem;
  isSelected: boolean;
  isVideoPaused?: boolean;
}>();
const { t } = useI18n();
const localePath = useLocalePath();
const router = useRouter();
const segment = useSegment();
const emit = defineEmits<{
  (event: "close-player"): void;
  (event: "select", selected: boolean): void;
  (event: "addToPlaylist" | "playlist-item-click" | "delete" | "edit", playlistItem: IPlaylistItem): void;
}>();
const baseURL = useRuntimeConfig().app.baseURL;
const isPrivatePlaylist = props.playlist?.type === PLAYLIST_TYPE.PRIVATE;
const canEdit = computed(() => isPrivatePlaylist && props.playlistItem.permissions.canEdit);
const canDelete = computed(() => isPrivatePlaylist && props.playlistItem.permissions.canDelete);
const isActive = computed(() => props.activeItem?.id === props.playlistItem.id);
const thumbnailStateIcon = computed(() => {
  if (isActive.value && props.isVideoPaused) {
    return "play";
  }
  else if (isActive.value) {
    return "pause-nocircle";
  }
  return "play";
});

const containerRef = ref<InstanceType<any>>(null);

function saveToPlaylist() {
  segment.track("Save Tag to Playlist", formatTrackPlaylistItem(props.playlistItem));

  emit("addToPlaylist", props.playlistItem);
}
function openEventPage() {
  segment.track("Go to Tag Match Page", formatTrackPlaylistItem(props.playlistItem));

  emit("close-player");
  nextTick(() => router.push(localePath(`/events/${props.playlistItem.playlist_source?.id}`)));
}
</script>

<template>
  <div
    class="group mb-1 flex h-[60px] cursor-pointer items-center justify-between hover:bg-neutral-light-50 hover:dark:bg-neutral-dark-600 ltr:pr-4 rtl:pl-4"
    :class="{ 'bg-neutral-light-50 dark:bg-neutral-dark-500': isActive }"
    @click="emit('playlist-item-click', props.playlistItem)"
  >
    <div
      class="flex basis-4/6 items-center"
      :class="!canEdit && 'pl-4'"
    >
      <UIcon
        v-if="canEdit"
        name="reorder"
        class="handle child cursor-move text-neutral-light-700 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
      />
      <div class="flex items-center gap-4 ltr:pl-2 rtl:pr-2">
        <UCheckbox
          :name="`playlist-item-${props.playlistItem.id}`"
          :model-value="props.isSelected"
          @update:model-value="emit('select', $event)"
        />
        <div class="relative flex">
          <div
            class="absolute z-[7] flex size-full items-center justify-center rounded-sm group-hover:bg-black/45"
            :class="{ 'bg-black/45': isActive }"
          >
            <RIcon
              class="cursor-pointer text-sm text-white opacity-0 group-hover:opacity-100"
              :class="{ 'opacity-100': isActive }"
              :name="thumbnailStateIcon"
            />
          </div>
          <RImage
            class="relative h-10 w-[72px] shrink-0 rounded-sm object-cover"
            :src="props.playlistItem.playlist_source.poster"
            :fallback-src="`${baseURL}images/no_poster.jpg`"
          />
        </div>
        <UClampedText
          class="max-w-28 flex-1 pr-1 text-sm font-medium text-black dark:text-white"
          :lines="2"
          :text="props.playlistItem.title || 'Tag'"
        />
      </div>
    </div>
    <div
      ref="containerRef"
      class="flex basis-2/6 items-center justify-end gap-4"
    >
      <IconPlaying
        v-if="isActive"
        :active="!props.isVideoPaused"
        class="inline-block pb-0.5 text-neutral-light-700"
      />
      <div
        v-if="props.playlistItem.playerNumber && !isActive"
        class="flex gap-1"
      >
        <UIcon
          name="t-shirt"
          class="inline-block pt-0.5 text-green-500"
        />
        <span
          v-tooltip="`#${props.playlistItem.playerNumber}`"
          class="max-w-12 truncate text-sm font-medium dark:text-white"
        >#{{ props.playlistItem.playerNumber }}</span>
      </div>
      <div
        v-if="props.playlistItem.playerNumber && !isActive"
        class="h-6 w-px bg-neutral-light-200"
      />
      <span
        v-if="!isActive"
        class="w-10 text-end text-sm font-medium dark:text-white"
      >
        {{ formatTime(props.playlistItem.startTime) }}
      </span>
      <UMenu :container-ref="containerRef">
        <UList>
          <UListItem
            icon="add"
            :text="t('labels.save_to_playlist')"
            @click="saveToPlaylist()"
          />
          <UListItem
            icon="vs"
            :text="t('labels.go_to_match_page')"
            @click="openEventPage()"
          />
          <UListItem
            v-if="canEdit"
            icon="edit"
            :text="t('labels.edit')"
            @click="() => emit('edit', props.playlistItem)"
          />
          <UListItem
            v-if="canDelete"
            :icon="{ name: 'delete', class: 'text-red-500 dark:text-red-500' }"
            class="!text-red-500"
            :text="t('labels.delete')"
            @click="() => emit('delete', props.playlistItem)"
          />
        </UList>
      </UMenu>
    </div>
  </div>
</template>
