<script setup lang="ts">
const localePath = useLocalePath();
const baseURL = useRuntimeConfig().app.baseURL;
const colorMode = useColorMode();
const darkLogo = `${baseURL}images/logo/pixellot-logo-dark.png`;
const lightLogo = `${baseURL}images/logo/pixellot-logo-light.png`;
const logoURL = computed(() => (colorMode?.value === "dark" ? lightLogo : darkLogo));

const props = defineProps<{
  title?: string;
  headerClass?: string;
  breadcrumbs?: { label: string; to?: string }[];
  hasDivider?: boolean;
  hideLogo?: boolean;
}>();
</script>

<template>
  <div class="pxl-container w-full overflow-auto">
    <AppHeader
      class="pt-6"
      :class="props.headerClass"
      has-auth-buttons
    >
      <template #pageTitle>
        <slot name="pageTitle">
          <div class="max-md:mt-6">
            <Breadcrumbs
              v-if="Number(props.breadcrumbs?.length) > 1"
              :data="props.breadcrumbs"
            />
            <h1
              v-else-if="props.title"
              class="text-2xl font-bold capitalize text-black dark:text-white"
            >
              {{ props.title || "--" }}
            </h1>
            <NuxtLink
              v-else-if="!props.hideLogo"
              :to="localePath('/auth/login/')"
            >
              <img
                :src="logoURL"
                width="140"
                height="22"
                aria-label="Pixellot logo"
                alt="Pixellot logo"
              >
            </NuxtLink>
          </div>
        </slot>
      </template>
    </AppHeader>

    <UDivider
      v-if="props.hasDivider"
      class="my-6"
    />

    <main class="mt-6 pb-10">
      <slot />
    </main>
  </div>
</template>
