<script lang="ts" setup>
import { twJoin } from "tailwind-merge";
import { PLAYLIST_TYPE, VIDEO_TYPE } from "@/constants";
import type { IPlayerState } from "~~/modules/pixellot-sdk";
import type { IBasicVideo, IEvent, IPlaylist, IPlaylistItem } from "@/types";
import formatDate from "@/common/helpers/format-date";

const props = defineProps<{
  video?: IEvent | IBasicVideo | null;
  playlist?: IPlaylist | null;
  isInPiPMode?: boolean;
  isInEditingMode?: boolean;
  activePlaylistItem?: IPlaylistItem | null;
  playerError?: boolean;
  playerState: IPlayerState | null;
  close: () => void;
  togglePiPMode: () => void;
  editPlaylist: (name?: string) => void;
}>();
const { t, locale } = useI18n();
const isMenuAvailable = computed<boolean>(() => {
  if (props.playlist?.type === PLAYLIST_TYPE.SYSTEM) {
    return false;
  }

  if (props.video?.type === "mbe-event") {
    return false;
  }

  return true;
});
const isDisabled = computed<boolean>(() => !props.video || props.video.urls.size === 0 || props.playerError);
const isViewsShown = computed<boolean>(
  () => props.video?.type !== "mbe-event" && !props.isInPiPMode && !props.playlist && !!props.video,
);
const isShareAvailable = computed<boolean>(
  () => !!props.playlist || props.video?.type !== "event" || ((props.video as IEvent)?.permissions?.canShare ?? false),
);
const isEvent = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IEvent => vid?.type === VIDEO_TYPE.EVENT;

function getShareTime(): number {
  return props.playerState?.currentTime ? Number(props.playerState.currentTime.toFixed(0)) : 0;
}
</script>

<template>
  <div
    class="flex items-center justify-between"
    :class="twJoin([isInPiPMode ? 'cursor-move' : '', 'py-3 px-4'])"
  >
    <div :class="isInPiPMode ? 'max-w-full' : 'max-w-[calc(100%-90px)]'">
      <template v-if="!isInEditingMode">
        <div
          class="truncate font-bold text-black dark:text-white"
          :class="!isInPiPMode ? 'h-[23px] text-base' : 'h-[19px] text-sm'"
        >
          {{ playlist?.name || video?.title }}
          <span
            v-if="playlist"
            class="text-neutral-light-800 dark:text-neutral-dark-200"
          >
            {{ playlist.size }}
          </span>
        </div>
        <p
          v-if="isViewsShown"
          class="text-xs font-medium text-neutral-light-800 dark:text-neutral-dark-200"
        >
          {{ t("labels.views") }}: {{ video!.views_count }}
        </p>
        <p
          v-else-if="(props.video as IEvent)?.start_date"
          class="text-xs font-medium text-neutral-light-800 dark:text-neutral-dark-200"
        >
          {{ formatDate((props.video as IEvent)?.start_date, "MMMM DD, YYYY hh:mm a", locale) }}
        </p>
      </template>
      <template v-else>
        <div
          class="truncate font-bold text-black dark:text-white"
          :class="!isInPiPMode ? 'h-[23px] text-base' : 'h-[19px] text-sm'"
        >
          {{ `${t("labels.edit")} ${activePlaylistItem?.title}` }}
        </div>
      </template>
    </div>

    <!-- ACTION BUTTONS -->
    <div
      v-if="!isInPiPMode"
      class="flex items-center gap-4"
    >
      <UMenu
        v-if="isMenuAvailable && !isInEditingMode"
        :trigger="{ icon: 'dots', variant: 'link-secondary', disabled: isDisabled }"
      >
        <UList>
          <MenuItemVideoShare
            v-if="isShareAvailable"
            :video="video"
            :playlist="playlist"
            :get-shared-time="getShareTime"
          />
          <MenuItemVideoBookmark
            :video="video"
            :playlist="playlist"
          />
          <MenuItemsEventRequestVideoExport
            v-if="!playlist && isEvent(video)"
            :event="video"
          />
          <MenuItemsVideoDownload
            v-if="video && !playlist"
            :video="video"
          />
          <MenuItemPlaylistEdit
            v-if="playlist"
            :playlist="playlist"
            :edit-playlist="(name?: string) => props.editPlaylist(name)"
          />
          <MenuItemVideoDelete
            :video="video"
            :playlist="playlist"
          />
        </UList>
      </UMenu>

      <UButton
        v-if="!isInEditingMode"
        size="lg"
        variant="link-secondary"
        :icon="{ name: 'picture-in-picture', class: 'h-5 w-5' }"
        :disabled="isDisabled"
        @click="togglePiPMode()"
      />

      <UButton
        size="lg"
        variant="link-secondary"
        :icon="{ name: 'close', class: 'h-5 w-5' }"
        @click="close()"
      />
    </div>
  </div>
</template>
