import type { FeatureFlagKey } from "~/account/constants/feature-flags";
import type { IUser } from "~/types";

function getLocalStorageItem<T>(key: string): T | null {
  const item = localStorage.getItem(key);
  return item ? (JSON.parse(item) as T) : null;
}

export function isSuperAdmin() {
  const user: IUser | null = getLocalStorageItem("user");

  return user?.hasSuperAdminRole;
}

export function isFeatureEnabled(name: FeatureFlagKey): boolean {
  const user: IUser | null = getLocalStorageItem("user");

  const appConfig = useAppConfig();
  const featureValue = appConfig.features[name];

  if (typeof featureValue === "boolean") {
    return featureValue;
  }

  if (typeof featureValue !== "object") {
    return false;
  }

  if (featureValue.superAdmins && isSuperAdmin()) {
    return true;
  }

  if (featureValue?.users?.length) {
    return featureValue.users.includes(user?.email ?? "");
  }

  return false;
}
