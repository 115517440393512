<script setup lang="ts">
import type { IPlaylist, ITag, IPlaylistItem } from "@/types";

const props = defineProps<{
  tags: ITag[] | IPlaylistItem[];
  eventId?: string;
  playlistId?: null | string;
  source?: "event" | "playlist";
}>();

const { t, locale } = useI18n();
const route = useRoute();
const toast = useToast();
const segment = useSegment();
const searchValue = ref("");
const emit = defineEmits<{
  "create-playlist": [hasExistingPlaylists: boolean];
  "close": [];
  "add-to-playlist-success": [];
}>();
const { state: playlistsResponse, isLoading: isLoadingPlaylists } = useAsyncState(
  () => {
    return getCachedUserPlaylists({ limit: 100, offset: 0 }).then((response) => {
      if (response.total === 0) {
        emit("create-playlist", false);
      }
      return response.data;
    });
  },
  [],
  {},
);

const playlists = computed(() => useSearchInArray(playlistsResponse.value, { q: searchValue.value, key: "name" }));
const { selectedItemsMap, toggleItemSelect } = useItemsSelect(playlists, "id");
const isEmptyList = computed(() => playlists.value.length < 1);
const isDisabled = computed(
  () => isLoadingPlaylists.value || isEmptyList.value || selectedItemsMap.size < 1 || isSubmitting.value,
);

const { isLoading: isSubmitting, execute: saveToPlaylist } = useAsyncState(
  () => {
    segment.track(
      "Tags Added to Playlist",
      formatTrackTagsPlaylist(props.tags, props.playlistId, props.eventId, props.source, selectedItemsMap.size),
    );

    return addTagsToPlaylist(
      props.tags,
      Array.from(selectedItemsMap.values()),
      (props.eventId || props.playlistId) as string,
      props.source,
      { accessToken: route.query.accessToken as string, locale: locale.value },
    );
  },
  null,
  {
    immediate: false,
    onSuccess: () => {
      toast.success(t("pages.playlists.modal.playlist_save_success", props.tags.length));
      emit("close");
      emit("add-to-playlist-success");
    },
    onError: err => toast.error(parseErrorMessage(err, { t })),
  },
);

function handleSelectPlaylist(playlist: IPlaylist, $event?: boolean) {
  if ($event) {
    toggleItemSelect($event, playlist);
  }
  else {
    toggleItemSelect(!selectedItemsMap.has(playlist.id), playlist);
  }
}
</script>

<template>
  <UModal
    appear
    :model-value="true"
  >
    <div class="flex items-center justify-end p-4 pb-3">
      <UButton
        title="Close"
        variant="link-secondary"
        icon="close"
        class="size-5"
        @click="emit('close')"
      />
    </div>

    <div class="md:px-10 md:pb-10">
      <h2
        id="modalHeading"
        class="text-2xl font-bold text-black dark:text-white"
      >
        {{ t('labels.save_to_playlist') }}
      </h2>

      <div class="flex flex-col">
        <UInput
          v-model="searchValue"
          class="mb-4 mt-6 max-md:grow"
          size="md"
          :placeholder="t('labels.search')"
          icon="search"
          variant="filled"
        />
        <div
          v-auto-animate
          class="relative flex h-[350px] w-[calc(100%+1rem)] flex-col gap-4 overflow-auto"
        >
          <RLoadingOverlay
            v-if="isLoadingPlaylists"
            class="bg-white !bg-opacity-100 dark:bg-neutral-dark-700"
          />
          <UButton
            v-for="playlist in playlists"
            :key="playlist.id"
            variant="link-primary"
            class="flex w-full justify-between rounded border px-4 py-[18px] duration-150"
            :class="[selectedItemsMap.has(playlist.id) ? 'border-blue-500' : 'border-neutral-light-200 hover:border-neutral-light-300 dark:border-neutral-dark-500 hover:dark:border-neutral-dark-300']"
            @click="handleSelectPlaylist(playlist)"
          >
            <div class="flex flex-col items-start gap-1">
              <div class="max-w-48 truncate text-sm font-bold capitalize text-black dark:text-white">
                {{ playlist.name }}
              </div>
              <div class="text-xs font-medium text-neutral-light-800">
                {{ playlist.size || 0 }} tags
              </div>
            </div>
            <div class="h-full">
              <UCheckbox
                :model-value="selectedItemsMap.has(playlist.id)"
                :name="`playlist-${playlist.id}`"
                @update:model-value="handleSelectPlaylist(playlist, $event)"
              />
            </div>
          </UButton>
        </div>

        <UButtonGroupConfirmCancel
          class="mt-6"
          cancel-variant="primary-outline"
          cancel-icon="add"
          :cancel-label="t('pages.playlists.modal.add_playlist')"
          :confirm-disabled="isDisabled"
          :confirm-label="t('pages.playlists.modal.done')"
          :on-cancel-click="() => emit('create-playlist', true)"
          :on-confirm-click="saveToPlaylist"
        />
      </div>
    </div>
  </UModal>
</template>
