<script lang="ts" setup>
const baseURL = useRuntimeConfig().app.baseURL;

const darkLogo = `${baseURL}images/logo/pixellot-logo-dark.png`;
const lightLogo = `${baseURL}images/logo/pixellot-logo-light.png`;
const smallLogo = `${baseURL}images/logo/pixellot-logo-small.png`;

const { t } = useI18n();
const user = useUser();
const userProfile = useUserProfile();
const navbarRef = ref();
const colorMode = useColorMode();
const isDark = computed(() => colorMode.value === "dark");
const isHovered = useElementHover(navbarRef);
const isOpened = useSessionStorage("nav-opened", true);
const toggleOpened = useToggle(isOpened);
const logoURL = computed(() => (isOpened.value ? (isDark.value ? lightLogo : darkLogo) : smallLogo));
const isB2CAdmin = computed(() => !!userProfile.value);
const { links, logoPath } = useNavbar();
</script>

<template>
  <nav
    ref="navbarRef"
    class="relative flex flex-col justify-between bg-neutral-light-200 px-4 py-8 transition-all duration-200 dark:bg-neutral-dark-700"
    :class="{
      'w-[14.5rem]': isOpened,
      'w-[5.25rem]': !isOpened,
    }"
  >
    <Transition>
      <!-- eslint-disable-next-line max-len -->
      <button
        v-show="isHovered"
        class="fixed top-9 grid size-6 place-items-center rounded-full bg-white shadow-sm hover:shadow-md dark:bg-neutral-dark-600 dark:text-white"
        :class="{ 'ltr:left-[4.5rem] rtl:right-[4.5rem]': !isOpened, 'ltr:left-[13.75rem] rtl:right-[13.75rem]': isOpened }"
        @click="toggleOpened()"
      >
        <UIcon
          name="chevron-left"
          style="font-size: 0.5rem"
          class="transition-transform duration-200"
          :class="isOpened ? 'rtl:rotate-180' : 'ltr:rotate-180'"
        />
      </button>
    </Transition>
    <div
      class="fixed flex h-[calc(100vh-4rem)] flex-col justify-between overflow-y-auto overflow-x-hidden"
      :class="{
        'w-[12.5rem]': isOpened,
        'w-[3.25rem]': !isOpened,
      }"
    >
      <div>
        <div class="mb-8 grid place-items-center overflow-hidden">
          <NuxtLink :to="logoPath">
            <img
              :src="logoURL"
              :width="isOpened ? '140' : '32'"
              :height="isOpened ? '22' : '22'"
              aria-label="Pixellot logo"
              alt="Pixellot logo"
            >
          </NuxtLink>
        </div>

        <div>
          <template
            v-for="item in links"
            :key="item.label"
          >
            <NuxtLink
              v-if="item.type === 'link' && !item.hidden"
              class="nav-link"
              :to="item.to"
              :class="{ active: $route.path.startsWith(item.to || '') }"
              active-class="active"
            >
              <UIcon
                v-if="item.iconName"
                :name="item.iconName"
                class="text-xl"
              />
              <span
                v-show="isOpened"
                class="truncate"
              >
                {{ item.label }}
              </span>
            </NuxtLink>
            <UDivider
              v-else-if="item.type === 'separator' && !item.hidden"
              class="my-4"
              :lighter="isDark"
            />
          </template>
        </div>
      </div>
      <div v-if="user">
        <UBanner
          v-if="!user.hasEmailVerified && !user.hasB2BAccount && isB2CAdmin"
          icon="verified"
          :text="isOpened ? t('labels.alert_email_verification') : ''"
          class="flex-col items-center text-center"
        />
      </div>
    </div>
  </nav>
</template>

<style>
.nav-link {
  @apply flex items-center gap-3 my-2 px-4 py-4 rounded text-neutral-light-800 dark:text-neutral-dark-200 text-base font-medium hover:text-white dark:hover:text-white hover:bg-blue-500;
}

.nav-link.active {
  @apply bg-blue-500 text-white;
}

.nuxt-icon svg {
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
