<script setup lang="ts">
const emit = defineEmits(["update:modelValue"]);
const props = defineProps<{
  name?: string;
  label?: string;
  labelClass?: string;
  inputClass?: string;
  value?: string | number | boolean;
  modelValue?: string | number | boolean | object | null;
  block?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  required?: boolean;
}>();
const { emitFormChange, formGroup } = useFormGroup();
const isDisabled = computed(() => props.disabled || !!formGroup?.disabled?.value);

const id = computed(() => `${props.name}-${props.value}`);
const pick = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
    if (value) {
      emitFormChange();
    }
  },
});
</script>

<template>
  <div class="relative flex items-center">
    <input
      :id="id"
      v-model="pick"
      :name="props.name"
      :required="props.required"
      :value="props.value"
      :disabled="isDisabled"
      type="radio"
      class="u-radio"
      :class="[props.inputClass, { hidden: props.hidden }]"
    >

    <label
      v-if="props.label || $slots.label"
      :for="id"
      :class="{
        'text-sm font-medium text-black dark:text-white': true,
        'u-label-text--required': props.required,
        'ms-3': !props.hidden,
        [String(props.labelClass)]: !!props.labelClass,
        'flex-1': props.block,
        'pxl-disabled': props.disabled,
      }"
    >
      <slot
        name="label"
        :label="props.label"
      >{{ props.label }}</slot>
    </label>
  </div>
</template>
