import type { ILocationObject } from "~/types";

export default function formatLocation(location?: ILocationObject | null, options?: Partial<{ fallback: string }>): string {
  const fallback = options?.fallback || "-";
  if (!location) {
    return fallback;
  }

  return location.country || location.state || location.city
    ? `${location.city || "N/A"}, ${location.state || "N/A"}, ${location.country || "N/A"}`
    : fallback;
}
