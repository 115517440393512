<script lang="ts" setup>
const props = defineProps<{
  hasAuthButtons?: boolean;
  hideMobileNav?: boolean;
}>();
const { t } = useI18n();
const user = useUser();
const [isSearchShown, toggleSearch] = useToggle(false);
const appConfig = useAppConfig();
</script>

<template>
  <header class="flex-row-reverse items-center justify-between md:flex">
    <div
      class="flex flex-wrap items-center justify-between gap-4 text-sm font-medium text-neutral-light-800 dark:text-neutral-dark-200"
    >
      <NavbarMobile
        v-if="user && !props.hideMobileNav"
        class="md:hidden"
      />
      <div class="right-menu flex grow flex-wrap items-center justify-end gap-4">
        <slot name="right-menu" />
        <UInput
          v-show="isSearchShown"
          class="hidden sm:block"
          :placeholder="t('labels.search')"
          icon="search"
          size="md"
        />
        <UIcon
          v-if="!appConfig.useMVP"
          name="search"
          class="cursor-pointer text-xl"
          @click="toggleSearch()"
        />
        <UIcon
          v-if="!appConfig.useMVP"
          name="notification"
          class="cursor-not-allowed text-xl"
        />
        <UserMenu />
        <div
          v-if="props.hasAuthButtons && !user"
          class="flex gap-4"
        >
          <UButton
            name="sign-in"
            variant="primary-outline"
            :to="{ path: '/auth/login/', query: { redirectUrl: $route.fullPath } }"
            :label="t('labels.sign_in')"
          />
          <UButton
            name="sign-up"
            variant="primary"
            :to="{ path: '/auth/sign-up/', query: { redirectUrl: $route.fullPath } }"
            :label="t('labels.sign_up')"
          />
        </div>
      </div>
    </div>

    <slot name="pageTitle" />
  </header>
</template>
