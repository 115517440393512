import { clone } from "./object";
import type { FeatureFlag, FeatureFlagObject } from "~/account/types/feature-flags";
import { formatFeatureName } from "~/common/composables/use-feature-flags";

export const formatFeatures = (features: FeatureFlagObject) =>
  Object.entries(features).map(([feature, value]) => ({
    key: feature,
    label: formatFeatureName(feature),
    enabled: value,
  }));

export const formatFeaturesConfig = (newConfig: FeatureFlag[], oldConfig: FeatureFlag[]): FeatureFlag[] => {
  const final = clone(oldConfig);

  return final.map((config) => {
    const featureConfig = newConfig.find(newConf => newConf.key === config.key);

    return featureConfig ? featureConfig : { ...config, isNewFeatureFlag: true };
  });
};
