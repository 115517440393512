<script lang="ts" setup>
import type { PxlIcon } from "@/common/components/U/Icon";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const variants = {
  default: "u-input-default",
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sizes = {
  sm: "u-input-sm",
  md: "u-input-md",
  lg: "u-input-lg",
};

// TODO: Move into separate file when such feature will be supported
interface ICommonInputProps {
  name?: string;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon?: PxlIcon;
  leadingIcon?: PxlIcon;
  trailingIcon?: PxlIcon;
  trailing?: boolean;
  leading?: boolean;
  hasError?: boolean;
  size?: keyof typeof sizes;
  variant?: keyof typeof variants;
}

const emit = defineEmits(["update:modelValue", "focus", "blur"]);
const props = withDefaults(
  defineProps<
    ICommonInputProps & {
      modelValue?: string | number;
      autofocus?: boolean;
      autoresize?: boolean;
      resize?: boolean;
      rows?: number;
    }
  >(),
  {
    modelValue: "",
    rows: 3,
  },
);
const { emitFormBlur, emitFormInput, formGroup } = useFormGroup();
const hasFocus = ref(false);
const inputId = computed(() => formGroup?.name.value || props.name);
const isDisabled = computed(() => props.disabled || !!formGroup?.disabled?.value);

const textarea = ref<HTMLTextAreaElement | null>(null);

const autoFocus = () => {
  if (props.autofocus) {
    textarea.value?.focus();
  }
};

const autoResize = () => {
  if (props.autoresize) {
    if (!textarea.value) {
      return;
    }

    textarea.value.rows = props.rows;

    const styles = window.getComputedStyle(textarea.value);
    const paddingTop = parseInt(styles.paddingTop);
    const paddingBottom = parseInt(styles.paddingBottom);
    const padding = paddingTop + paddingBottom;
    const lineHeight = parseInt(styles.lineHeight);
    const { scrollHeight } = textarea.value;
    const newRows = (scrollHeight - padding) / lineHeight;

    if (newRows > props.rows) {
      textarea.value.rows = newRows;
    }
  }
};

const onInput = (event: InputEvent) => {
  autoResize();

  emit("update:modelValue", (event.target as HTMLInputElement).value);
  emitFormInput();
};

const onBlur = (event: FocusEvent) => {
  hasFocus.value = false;
  emitFormBlur();
  emit("blur", event);
};
const onFocus = (event: FocusEvent) => {
  hasFocus.value = true;
  emit("focus", event);
};

onMounted(() => setTimeout(() => autoFocus(), 100));
onMounted(() => setTimeout(() => autoResize(), 100));

watch(
  () => props.modelValue,
  () => nextTick(autoResize),
);
</script>

<template>
  <UInputControl
    class="!px-0"
    :loading="props.loading"
    :disabled="props.disabled"
    :icon="props.icon"
    :leading-icon="props.leadingIcon"
    :trailing-icon="props.trailingIcon"
    :trailing="props.trailing"
    :leading="props.leading"
    :has-error="props.hasError"
    :has-focus="hasFocus"
    :size="props.size"
    :variant="props.variant"
  >
    <textarea
      :id="inputId"
      ref="textarea"
      class="u-input-field p-3"
      :value="props.modelValue"
      :name="props.name"
      :rows="props.rows"
      :required="props.required"
      :disabled="isDisabled"
      :placeholder="props.placeholder"
      v-bind="$attrs"
      @input="(e) => onInput(e as InputEvent)"
      @blur="onBlur"
      @focus="onFocus"
    />
  </UInputControl>
</template>
