import type { FeatureFlagObject } from "~/account/types/feature-flags";

const defaults = {
  // enable_{action}
  enable_leagues_flow: false,
  enable_join_team_flow: false,
  enable_playon_organizations: false,

  // enable_{entity}_{action}

  // Event
  enable_event_team_stats_tab: false,
  enable_event_stats_export_to_pdf: false,
  enable_event_athlete_stats_tab: false,
  enable_event_add_game_info: false,
  enable_event_leaders: false,
  enable_team_leaders: false,

  // Team
  enable_team_manage_rosters_vs_deep_link: false,
  enable_team_overview_tab: false,
  enable_team_stats_tab: false,
  enable_team_athletes_tab: false,
  enable_team_video_upload: false,
  enable_team_video_upload_multiple_files_per_angle: false,
  enable_team_video_upload_multiple_angles: false,
  enable_team_video_import_replay: false,
  enable_team_video_import: false,

  // League
  enable_league_standings_tab: false,
  enable_league_leaderboards_tab: false,
  enable_league_athletes_tab: false,

  // Athlete
  enable_athlete_add_season: false,
  enable_athletes_index_page: false,
  enable_athletes_create_page: false,
  enable_athletes_edit_page: false,
  enable_athletes_delete: false,
  enable_athlete_overview_tab: false,
  enable_athlete_bio_tab: false,
  enable_athlete_videos_tab: false,
  enable_athlete_stats_tab: false,
  enable_athlete_charts_tab: false,
  enable_athletes_merge: false,
};

export type FeatureFlagKey = keyof typeof defaults;

export const defaultFeatureFlags = defaults as FeatureFlagObject;
