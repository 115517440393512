<script setup lang="ts">
import { RDrawer } from "#components";

const emit = defineEmits<{
  (event: "close"): void;
}>();

const { t } = useI18n();
const data = ref({ rating: 4, text: "" });
const segment = useSegment();
const toast = useToast();

function sendFeedback() {
  segment.track("User Left Feedback", { rating: data.value.rating, user_comment: data.value.text });
  toast.success(t("labels.feedback_sent_success_message"));
}
</script>

<template>
  <RDrawer
    ref="feedbackDrawerRef"
    is-opened
    has-close
    @close="emit('close')"
  >
    <template #content="{ close }">
      <div class="px-4 py-10 md:p-10">
        <div class="flex flex-col gap-6">
          <h5 class="text-2xl font-bold">
            {{ t("labels.send_feedback") }}
          </h5>

          <div class="flex flex-col gap-4">
            <p class="text-sm font-medium">
              {{ t("labels.send_feedback_description") }}
            </p>

            <RRating v-model="data.rating" />
          </div>

          <UTextarea
            v-model="data.text"
            :placeholder="t('labels.feedback_placeholder')"
            :rows="5"
            autoresize
          />
        </div>

        <div class="mt-8 flex items-center gap-4">
          <UButton
            class="flex-1"
            size="lg"
            variant="secondary"
            :label="t('labels.cancel')"
            @click="close()"
          />
          <UButton
            class="flex-1"
            size="lg"
            :label="t('labels.send')"
            @click="sendFeedback(); close()"
          />
        </div>
      </div>
    </template>
  </RDrawer>
</template>
