import type { ICountry } from "~/types";

export default function useCountries() {
  const { locale } = useI18n();
  const countries = ref<ICountry[]>([]);
  const countriesCache = useSessionStorage<ICountry[]>("countries", []);
  const countriesLoaded = ref(false);

  const intlCache = ref<Record<string, Intl.DisplayNames>>({});

  const getIntlDisplayNames = (lang: string) => {
    if (!intlCache.value[lang]) {
      intlCache.value[lang] = new Intl.DisplayNames([lang], { type: "region" });
    }
    return intlCache.value[lang];
  };

  const localizedCountries = computed(() => {
    const intl = getIntlDisplayNames(locale.value);

    return countries.value.map((country) => {
      const localizedName = country.code ? intl.of(country.code) : country.title;

      return {
        ...country,
        label: localizedName || country.title,
        title: country.title,
        value: country.code,
        description: `${localizedName || country.title} (${country.operatorCode})`,
      };
    });
  });

  function loadCountries() {
    return $fetch<{ data: ICountry[] }>("/json/countries.json")
      .then((response) => {
        countries.value = response.data;
        countriesCache.value = response.data;
        countriesLoaded.value = true;
      })
      .catch(console.error);
  }

  function getCountryTitleByCode(code: string) {
    const country = countries.value.find(c => c.code === code);
    return country ? country.title : code;
  }

  onMounted(() => {
    const hasCachedCountries = Boolean(countriesCache.value && countriesCache.value.length);

    if (hasCachedCountries) {
      countries.value = countriesCache.value;
      countriesLoaded.value = true;
    }
    else {
      loadCountries();
    }
  });

  return { countries: localizedCountries, countriesLoaded, getCountryTitleByCode };
}
