export default function getLightness(hexColor: string): number {
  // Remove optional leading hash
  const hexColorPure = hexColor.replace(/^#/, "");

  // Convert hex to RGB values
  const rgb = hexColorPure.match(/.{2}/g)!.map(hex => parseInt(hex, 16));

  // Calculate relative luminance using WCAG formula
  const luminance = (0.2126 * rgb[0]) / 255 + (0.7152 * rgb[1]) / 255 + (0.0722 * rgb[2]) / 255;

  // Scale luminance to 0-100 range
  const lightness = Math.round(luminance * 100);

  return lightness;
}
