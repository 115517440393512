import formatTeam, { getFullName } from "../../teams/helpers/format-team";
import type { components } from "#build/types/nuxt-open-fetch/atlit";
import type { IAthlete, IAthleteRoster, IResponseClub } from "~/types";

export default function formatAthlete(
  data: components["schemas"]["Athlete"] & {
    relationships?: {
      roster?: components["schemas"]["RosterAthlete"][];
      membership?: components["schemas"]["Membership"][];
      club?: components["schemas"]["Team"][];
    };
    meta?: components["schemas"]["MetaObject"];
  },
): IAthlete {
  return {
    id: data.id!,
    userId: data.attributes?.userId,
    name: getFullName(data.attributes || {}),
    firstName: data.attributes?.firstName || "",
    middleName: data.attributes?.middleName || "",
    lastName: data.attributes?.lastName || "",
    // data.attributes?.name!,
    jersey: data.attributes?.jersey,
    positions: data.attributes?.positions || [],
    logo_url: data.attributes?.logo,
    // Athletes don't have a sport type associations
    // that's why we're using the sport type from the first club that athlete relates to
    sport_type: data.relationships?.club?.[0].attributes.sportType,
    teams: data.relationships?.club?.map(club => formatTeam(club as IResponseClub)) || [],
    bio: data.attributes?.bio || {},
    rosters: data.relationships?.roster?.map(roster => formatAthleteRoster(roster)) || [],
    permissions: {
      availableOverview: isFeatureEnabled("enable_athlete_overview_tab") || false,
      availableStats: (isFeatureEnabled("enable_athlete_stats_tab") && data.meta?.permissions?.includes("stats:overview")) || false,
      availableBio: isFeatureEnabled("enable_athlete_bio_tab") || false,
      availableVideos: isFeatureEnabled("enable_athlete_videos_tab") || false,
      availableCharts: isFeatureEnabled("enable_athlete_charts_tab") || false,
      canEditAthlete: isFeatureEnabled("enable_athletes_edit_page") || false,
      canMergeAthlete: (isFeatureEnabled("enable_athletes_merge") && isSuperAdmin()) || false,
      canDeleteAthlete: isFeatureEnabled("enable_athletes_delete") || false,
    },
  };
}

export function formatAthleteRoster(data: components["schemas"]["RosterAthlete"]): IAthleteRoster {
  return {
    id: data.id!,
    clubId: data.attributes?.roster?.clubId ?? "",
    athleteId: data.attributes?.athleteId ?? "",
    jersey: data.attributes?.jersey,
    positions: data.attributes?.positions || [],
    season: data.attributes?.roster?.season,
    status: data.attributes?.status,
  };
}
