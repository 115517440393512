<script lang="ts" setup>
export interface BreadcrumbsProps {
  data?: { label: string; to?: string }[];
}

const props = withDefaults(defineProps<BreadcrumbsProps>(), {
  data: () => [],
});

const breadcrumbs = computed(() => props.data || []);
</script>

<template>
  <div class="flex items-center text-sm font-medium text-neutral-light-800 dark:text-neutral-dark-200">
    <template
      v-for="(crumb, index) in breadcrumbs"
      :key="crumb"
    >
      <div
        v-if="breadcrumbs.length - 1 !== index"
        class="relative flex items-center"
      >
        <NuxtLink :to="crumb.to">
          {{ crumb.label }}
        </NuxtLink>
        <UIcon
          name="chevron-right"
          style="font-size: 5px"
          class="mx-2"
        />
      </div>
      <div
        v-else
        class="relative flex items-center"
      >
        <span>{{ crumb.label }}</span>
      </div>
    </template>
  </div>
</template>
